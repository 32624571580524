import {Document, Paragraph, Packer, TextRun, HeadingLevel, AlignmentType } from 'docx';
import { saveAs, } from 'file-saver';
import moment from 'moment';

export default (data) => {
  const doc = new Document();
  let content = [
    new Paragraph({children: [new TextRun({text: `E-Dignity Report`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_1, alignment: AlignmentType.CENTER,}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun({text: `I. INTRODUCTION`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5 }),
    new Paragraph({children: [new TextRun(`The following constitutes my Confidential Investigation and Report concerning claims regarding ${data.reportForm.victimName}, a ${data.reportForm.studentGrade}th grade student at ${data.complaintData.schoolName}, about whom a DASA complaint(s) was filed on ${moment(data.complaintData.complaintDate).format('MMMM DD, YYYY')}.`)]}),
    new Paragraph({children: [new TextRun({text: `II. NATURE OF THE COMPLAINT/ALLEGATIONS`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
    new Paragraph({children: [new TextRun(`On ${moment(data.complaintData.complaintDate).format('MMMM DD, YYYY')} it was brought to my attention that the following alleged incidents occurred:`)]}),
  ]
 
  for (let inc in data.complaintData.incidents) {
    content.push(new Paragraph({children: [new TextRun(`${data.reportForm.complainantName} has alleged that on ${moment(data.complaintData.incidents[inc].date).format('MMMM DD, YYYY')} at ${data.complaintData.incidents[inc].place}, ${data.complaintData.incidents[inc].description}.`)]}));
    content.push(new Paragraph({children: [new TextRun(`The complainant is seeking the following corrective action:  ${data.complaintData.incidents[inc].reliefSought}`)]}));
  }
  let section3 = [
    new Paragraph({children: [new TextRun({text: `III. RELEVANT LAW AND POLICIES`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
    new Paragraph({children: [new TextRun({text: `  DISTRICT CODE OF CONDUCT`, bold: true, color: '000000'})]}),
    new Paragraph({children: [new TextRun({text: `  DISTRICT NON DISCRIMINATION POLICY`, bold: true, color: '000000'})]}),
    new Paragraph({children: [new TextRun({text: `  DISTRICT DASA POLICY`, bold: true, color: '000000'})]}),
    new Paragraph({children: [new TextRun({text: `IV. INVESTIGATION`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
  ]
  for (let section of section3) content.push(section);
  for (let inc of data.complaintData.incidents) {
    let incidentData = [
      new Paragraph({children: [new TextRun({text: `Incident ${inc.incidentNumber} `, underline: true, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
      new Paragraph({children: [new TextRun({text: `  A. The following complainant was interviewed as part of this investigation:`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
      new Paragraph({children: [new TextRun({text: `  ${data.reportForm.complainantName}, ${data.reportForm[`complainantInterviewedOn__incident-${inc.incidentNumber}`] ? moment(data.reportForm[`complainantInterviewedOn__incident-${inc.incidentNumber}`]).format('MMMM DD, YYYY') : 'N/A'}`})]}),
      new Paragraph({children: [new TextRun({text: `  Complainant ${data.reportForm.complainantName} said: ${data.reportForm[`complainantSaid__incident-${inc.incidentNumber}`] ? data.reportForm[`complainantSaid__incident-${inc.incidentNumber}`] : 'N/A'} `})]}),
      new Paragraph({children: [new TextRun({text: `  Notes: ${data.reportForm[`complainantNote__incident-${inc.incidentNumber}`] ? data.reportForm[`complainantNote__incident-${inc.incidentNumber}`] : 'N/A'}`})]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun({text: `  B. Victim Interviewed - Incident ${inc.incidentNumber}`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}),
      new Paragraph({children: [new TextRun({text: `  ${data.reportForm.victimName}, ${data.reportForm[`victimInterviewedOn__incident-${inc.incidentNumber}`] ? moment(data.reportForm[`victimInterviewedOn__incident-${inc.incidentNumber}`]).format('MMMM DD, YYYY') : 'N/A'}`})]}),
      new Paragraph({children: [new TextRun({text: `  The following victim were interviewed as part of this investigation:`})]}),
      new Paragraph({children: [new TextRun({text: `  Victim ${data.reportForm.victimName} said: ${data.reportForm[`victimSaid__incident-${inc.incidentNumber}`] ? data.reportForm[`victimSaid__incident-${inc.incidentNumber}`] : 'N/A'}`})]}),
      new Paragraph({children: [new TextRun({text: `  Notes: ${data.reportForm[`victimNote__incident-${inc.incidentNumber}`] ? data.reportForm[`victimNote__incident-${inc.incidentNumber}`] : 'N/A'}`})]}),
      new Paragraph({children: [new TextRun(``)]}),
    ];
    incidentData.push(new Paragraph({children: [new TextRun({text: `  C. Accused Interviewed - Incident ${inc.incidentNumber}`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}));
    incidentData.push(new Paragraph({children: [new TextRun({text: `  The following accused were interviewed as part of this investigation:`})]}));
    incidentData.push(new Paragraph({children: [new TextRun(``)]}));
    for (let [index] of data.complaintData.incidents[inc.incidentNumber - 1].accused.entries()) {
      incidentData.push(new Paragraph({children: [
        new TextRun(`  ${data.reportForm[`accusedName-${index + 1}__incident-${inc.incidentNumber}`]}, ${data.reportForm[`accusedInterviewedOn-${index + 1}__incident-${inc.incidentNumber}`] ? moment(data.reportForm[`accusedInterviewedOn-${index + 1}__incident-${inc.incidentNumber}`]).format('MMMM DD, YYYY') : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [
        new TextRun(`  Accused ${data.reportForm[`accusedName-${index + 1}__incident-${inc.incidentNumber}`]} said: ${data.reportForm[`accusedSaid-${index + 1}__incident-${inc.incidentNumber}`] ? data.reportForm[`accusedSaid-${index + 1}__incident-${inc.incidentNumber}`] : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [
        new TextRun(`  Notes: ${data.reportForm[`accusedNote-${index + 1}__incident-${inc.incidentNumber}`] ? data.reportForm[`accusedNote-${index + 1}__incident-${inc.incidentNumber}`] : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [new TextRun(``)]}));
    }
    incidentData.push(new Paragraph({children: [new TextRun({text: `  D. Witness(es) interviewed - Incident ${inc.incidentNumber}`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}));
    incidentData.push(new Paragraph({children: [new TextRun({text: `  The following individuals/ witnesses were interviewed as part of this investigation:`})]}));
    incidentData.push(new Paragraph({children: [new TextRun(``)]}));
    for (let [index] of data.complaintData.incidents[inc.incidentNumber - 1].witness.entries()) {
      incidentData.push(new Paragraph({children: [
        new TextRun(`  ${data.reportForm[`witnessName-${index + 1}__incident-${inc.incidentNumber}`]}, ${data.reportForm[`witnessInterviewedOn-${index + 1}__incident-${inc.incidentNumber}`] ? moment(data.reportForm[`accusedInterviewedOn-${index + 1}__incident-${inc.incidentNumber}`]).format('MMMM DD, YYYY') : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [
        new TextRun(`  Witness ${data.reportForm[`witnessName-${index + 1}__incident-${inc.incidentNumber}`]} said: ${data.reportForm[`witnessSaid-${index + 1}__incident-${inc.incidentNumber}`] ? data.reportForm[`witnessSaid-${index + 1}__incident-${inc.incidentNumber}`] : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [
        new TextRun(`  Notes: ${data.reportForm[`witnessNote-${index + 1}__incident-${inc.incidentNumber}`] ? data.reportForm[`witnessNote-${index + 1}__incident-${inc.incidentNumber}`] : 'N/A'}`)
      ]}));
      incidentData.push(new Paragraph({children: [new TextRun(``)]}));
    }
    for (let incident of incidentData) content.push(incident);
  }
  
  content.push(new Paragraph({children: [new TextRun({text: `V. FINDINGS AND CONCLUSIONS`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}));
  content.push(new Paragraph({children: [new TextRun({text: `The following constitutes my findings and conclusions based on the witness interviews and documentation I was provided regarding each of the alleged incidents.`})]}));
  let incidentCinclusion = [];
  for (let inc of data.complaintData.incidents) {
    incidentCinclusion.push(new Paragraph({children: [new TextRun({text: `Incident ${inc.incidentNumber} - ${data.reportForm[`conclusion__incident-${inc.incidentNumber}`] ? data.reportForm[`conclusion__incident-${inc.incidentNumber}`] : 'N/A'}`})]}));
    incidentCinclusion.push(new Paragraph({children: [new TextRun({text: `  Notes: ${data.reportForm[`conclusionNote__incident-${inc.incidentNumber}`] ? data.reportForm[`conclusionNote__incident-${inc.incidentNumber}`] : 'N/A'}`})]}));
  }
  for (let conclusion of incidentCinclusion) content.push(conclusion);
  content.push(new Paragraph({children: [new TextRun(``)]}));
  content.push(new Paragraph({children: [new TextRun({text: `VI. REMEDIAL ACTION`, bold: true, color: '000000'})], heading: HeadingLevel.HEADING_5}));
  content.push(new Paragraph({children: [new TextRun({text: `The following remedial actions were taken.`})]}));
  let incidentRemedialActions = [];
  for (let inc of data.complaintData.incidents) {
    incidentRemedialActions.push(new Paragraph({children: [new TextRun({text: `Incident ${inc.incidentNumber}`, bold: true, color: '000000'})]}));
    if (data.reportForm[`remedialActions__incident-${inc.incidentNumber}`]) {
      for (let item of data.reportForm[`remedialActions__incident-${inc.incidentNumber}`]) {
        incidentRemedialActions.push(new Paragraph({children: [new TextRun({text: `  ${item}`})]}));
      }
    }
  }
  for (let actions of incidentRemedialActions) content.push(actions);

  content.push(new Paragraph({children: [new TextRun(``)]}));
  content.push(new Paragraph({children: [new TextRun(``)]}));
  content.push(new Paragraph({children: [new TextRun({text: `Dated: ${data.reportForm.reportDate ? moment(data.reportForm.reportDate).format('MMMM DD, YYYY') : 'N/A'}`})]}));
  content.push(new Paragraph({children: [new TextRun({text: `By: ${data.userFullName}`})]}));
  doc.addSection({
    properties: {},
    children: content
  });
  Packer.toBlob(doc).then(blob => {
    saveAs(blob, "Investigation Report.docx");
  });
}