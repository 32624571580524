import {Document, Paragraph, Packer, TextRun} from 'docx';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default (data) => {
  const doc = new Document();
  doc.addSection({
    properties: {},
    children: [
      new Paragraph({children: [new TextRun(`${moment().format('MMMM DD, YYYY')}`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`VIA HAND DELIVERY & CERTIFIED MAIL`)]}),
      new Paragraph({children: [new TextRun(`${data.victimName}`)]}),
      new Paragraph({children: [new TextRun(`${data.address}`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`Parent or Guardian of  ${data.complainantName}:`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`This letter is an acknowledgement of a complaint dated ${moment(data.compDate).format('MMMM DD, YYYY')}.`)]}),
      new Paragraph({children: [new TextRun(`In accordance with District Policy and Regulation, your complaint will be investigated and is expected to be completed by ${moment(data.compDate).add(data.timeline,'days').format('MMMM DD, YYYY')}. You will be contacted directly with regard to the investigation process and changes, if any, in the investigation timeline. You should address any questions with regard to the status of the investigation directly to myself. Enclosed for your reference are copies of the District’s Policy and Regulation.`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`Sincerely,`)]}),
      new Paragraph({children: [new TextRun(`${data.userFullName}`)]}),
    ],
  });
  
  Packer.toBlob(doc).then(blob => {
    saveAs(blob, "Dasa-Acknowledgement-Letter.docx");
  });
}