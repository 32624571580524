import {SubmissionError} from 'redux-form';
// import parseResponse from 'utils/parseResponse';
import {CREATE_SCHOOL} from '../../../config/index';

export default async (data, token) => {
  const body = {
    schoolData: data
  }
  const response = await fetch(`${CREATE_SCHOOL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(body),
  });
  try {
    return await response.json();
  } catch (e) {
    throw new SubmissionError(e);
  }
};
