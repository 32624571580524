import {UPDATE_DATA} from '../config/index';

async function fetchData(props) {
  return await (await fetch(`${UPDATE_DATA}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  })).json();
}
export default fetchData;