import React, {useState, useEffect} from 'react';
import main from '../../containers/main';
import InvestigationForm from '../../components/InvestigationForm/InvestigationForm';
import onSubmit from './onSubmit';
import './styles.scss';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import { Button } from 'react-bootstrap';
import {GET_REPORT, GET_COMPLAINT} from '../../config/index';

function InvestigationReport (props) {
  const {complaints, user, history, token, report, updateComplaint, setFormState, setPreloader, setReport} = props;
  const complaintID = new URLSearchParams(props.location.search).get("id");
  const [complaintData, setComplaintData] = useState(null);
  
 
  const handleBack = () => history.goBack();
  const handleSubmit = async (prop) => {
    let incidents = []
    for (let i = 1; i <= complaintData.incidents.length; i++) {
      incidents.push({});
      for (let item in prop) {
        if (item.indexOf(`complainantName__incident-${i}`) !== -1) incidents[i-1]['complainantName'] = prop[item];
        if (item.indexOf(`complainantInterviewedOn__incident-${i}`) !== -1) incidents[i-1]['complainantInterviewedOn'] = prop[item];
        if (item.indexOf(`complainantSaid__incident-${i}`) !== -1) incidents[i-1]['complainantSaid'] = prop[item];
        if (item.indexOf(`complainantNote__incident-${i}`) !== -1) incidents[i-1]['complainantNote'] = prop[item];
        if (item.indexOf(`incidentId__incident-${i}`) !== -1) incidents[i-1]['incidentId'] = prop[item];

        if (item.indexOf(`victimName__incident-${i}`) !== -1) incidents[i-1]['victimName'] = prop[item];
        if (item.indexOf(`victimInterviewedOn__incident-${i}`) !== -1) incidents[i-1]['victimInterviewedOn'] = prop[item];
        if (item.indexOf(`victimSaid__incident-${i}`) !== -1) incidents[i-1]['victimSaid'] = prop[item];
        if (item.indexOf(`victimNote__incident-${i}`) !== -1) incidents[i-1]['victimNote'] = prop[item];
        
        if (item.indexOf(`conclusion__incident-${i}`) !== -1) incidents[i-1]['conclusion'] = prop[item];
        if (item.indexOf(`conclusionNote__incident-${i}`) !== -1) incidents[i-1]['conclusionNote'] = prop[item];
        if (item.indexOf(`remedialActions__incident-${i}`) !== -1) incidents[i-1]['remedialActions'] = prop[item];
        if (prop[`conclusion__incident-${i}`] && prop[`conclusion__incident-${i}`] === 'Founded') {
          if (item.indexOf(`incidentConstitutes__incident-${i}`) !== -1) incidents[i-1]['incidentConstitutes'] = prop[item];
          if (item.indexOf(`whereIncidentOccurred__incident-${i}`) !== -1) incidents[i-1]['whereIncidentOccurred'] = prop[item];
          if (item.indexOf(`whenIncidentOccurred__incident-${i}`) !== -1) incidents[i-1]['whenIncidentOccurred'] = prop[item];
          if (item.indexOf(`howIncidentOccurred__incident-${i}`) !== -1) incidents[i-1]['howIncidentOccurred'] = prop[item];
          if (item.indexOf(`weapon__incident-${i}`) !== -1) incidents[i-1]['weapon'] = prop[item];
          if (item.indexOf(`drugs__incident-${i}`) !== -1) incidents[i-1]['drugs'] = prop[item];
          if (item.indexOf(`alcohol__incident-${i}`) !== -1) incidents[i-1]['alcohol'] = prop[item];
          if (item.indexOf(`offenders__incident-${i}`) !== -1) incidents[i-1]['offenders'] = prop[item];
        }
      }
    }

    for (let i = 1; i <= complaintData.incidents.length; i++) {
      incidents[i-1]['accused'] = [];
      incidents[i-1]['witness'] = [];

      for (let j = 1; j <= complaintData.incidents[i-1].accused.length; j++) {
        incidents[i-1].accused.push({});
        incidents[i-1].witness.push({});
        for (let item in prop) {
          if (item.indexOf(`accusedName-${j}__incident-${i}`) !== -1) incidents[i-1].accused[j-1]['accusedName'] = prop[item];
          if (item.indexOf(`accusedInterviewedOn-${j}__incident-${i}`) !== -1) incidents[i-1].accused[j-1]['accusedInterviewedOn'] = prop[item];
          if (item.indexOf(`accusedSaid-${j}__incident-${i}`) !== -1) incidents[i-1].accused[j-1]['accusedSaid'] = prop[item];
          if (item.indexOf(`accusedNote-${j}__incident-${i}`) !== -1) incidents[i-1].accused[j-1]['accusedNote'] = prop[item];
          
          if (item.indexOf(`witnessName-${j}__incident-${i}`) !== -1) incidents[i-1].witness[j-1]['witnessName'] = prop[item];
          if (item.indexOf(`witnessInterviewedOn-${j}__incident-${i}`) !== -1) incidents[i-1].witness[j-1]['witnessInterviewedOn'] = prop[item];
          if (item.indexOf(`witnessSaid-${j}__incident-${i}`) !== -1) incidents[i-1].witness[j-1]['witnessSaid'] = prop[item];
          if (item.indexOf(`witnessNote-${j}__incident-${i}`) !== -1) incidents[i-1].witness[j-1]['witnessNote'] = prop[item];
        }
      }
    }
    const investigationData = {
      studentGrade: prop.studentGrade ? prop.studentGrade : '',
      complainantName: prop.complainantName,
      victimName: `${complaintData.studentFirstName} ${complaintData.studentLastName}`,
      incidents,
      status: prop.status ? prop.status : 'Opened',
      user_id: user._id,
      school_id: complaintData.school_id,
      complaint_id: complaintData._id,
      reportDate: prop.reportDate
    }
    // setPreloader(true);
    const getResponse = await onSubmit({token, investigationData});
    updateComplaint({_id: complaintData._id, status: prop.status ? prop.status : 'Opened'})
    if (getResponse.success) {
      createNotification({type: 'success', message: 'Report saved'});
      setFormState(prop);
    } else {
      createNotification({type: 'error', message: 'Report not saved'});
    }
    // setPreloader(false);
    return prop
  }
 
  const getComplaint = async () => {
    setPreloader(true);
    const jsonData = await (await fetch(`${GET_COMPLAINT}?id=${complaintID}`, {
      method: 'get',
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
    })).json();

    if (jsonData.success) {
      setComplaintData(jsonData.complaint);
    } else if (!jsonData.success && jsonData.code === 401) {
      history.push('/login');
    }
    setPreloader(false);
  }
  const getReport = async () => {
    setPreloader(true);
    const jsonData = await (await fetch(`${GET_REPORT}?id=${complaintID}`, {
      method: 'get',
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
    })).json();

    if (jsonData.success) {
      setReport(jsonData.report);
    } else if (!jsonData.success && jsonData.code === 401) {
      history.push('/login');
    } else {
      setReport({});
    }
    setPreloader(false);
  }
  useEffect(() => {
    let complaint = complaints.filter(val => {
      return val._id === complaintID;
    })[0];
    if (complaint) {
      setComplaintData(complaint)
    } else {
      getComplaint();
      getReport();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="container report">
      <NotificationContainer/>
      <div className="row page-header">
        <h3>Investigation Report Form</h3>
        <Button
          variant="info"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className="row">
        {complaintData && report
          ? <>
              <InvestigationForm history={history} onSubmit={handleSubmit} complaintData={complaintData}/>
            </>
          : null
        }
        
      </div>
    </div>
  )
}
export default main(InvestigationReport);