import React, {useState, useEffect} from 'react';
import Input from '../Input/Input';
import main from '../../containers/main';
import {Field, reduxForm, change} from 'redux-form';
import {required, email} from '../../utils';
import { Form, Col, Row, Button } from 'react-bootstrap';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import './styles.scss';
import ModalUpdatePass from './ModalUpdatePass/ModalUpdatePass';
import onUpdateUser from './onUpdateUser';

let ProfileForm = (props) => {
  const { handleSubmit, setPreloader, schools, user, token, dispatch } = props;
  const [showModalUpdatePass, setShowModalUpdatePass] = useState(false);
  const handleCloseModalUpdatePass = () => setShowModalUpdatePass(false);
  const handleShowModalUpdatePass = () => setShowModalUpdatePass(true);

  useEffect(() => {
    if (user) {
      dispatch(change('ProfileForm', 'firstName', user.firstName));
      dispatch(change('ProfileForm', 'lastName', user.lastName));
      dispatch(change('ProfileForm', 'email', user.email));
      dispatch(change('ProfileForm', 'phone', user.phone));

      dispatch(change('ProfileForm', 'schoolName', user.school_id ? schools.name : ''));
      dispatch(change('ProfileForm', 'roleName', user.roleName === 'superAdmin' 
        ? 'Super Admin'
        : user.roleName === 'districtAdmin'
          ? 'District Admin'
          : 'User'));
    }
  }, [dispatch, user, schools])
  const handleUpdateUser = async (prop) => {
    setPreloader(true);
    const data = {
      userID: user._id,
      updatedData: prop
    }
    const response = await onUpdateUser(data, token);

    if (response.success) {
      createNotification({type: 'success', message: response.message});
    } else {
      createNotification({type: 'error', message: response.message});
    }
    setPreloader(false);
    handleCloseModalUpdatePass();
  }
  
  return (
    <div className="profile-form">
      <NotificationContainer/>
      <form onSubmit={handleSubmit} noValidate>
        <Form.Group as={Row}>
          <Form.Label column sm="2" htmlFor="email">Email</Form.Label>
            <Col sm={4}>
              <Field
                name="email"
                disabled={true}
                className="input"
                component={Input}
                type="email"
                validate={[required, email]}
                />
           </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2" htmlFor="firstName">First Name</Form.Label>
            <Col sm={4}>
              <Field
                name="firstName"
                className="input"
                component={Input}
                type="text"
                // disabled={editMode}
                validate={[required]}
                />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2" htmlFor="lastName">Last Name</Form.Label>
            <Col sm={4}>
              <Field
                name="lastName"
                // disabled={editMode}
                className="input"
                component={Input}
                type="text"
                validate={[required]}
                />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2" htmlFor="roleName">Title</Form.Label>
            <Col sm={4}>
              <Field
                name="roleName"
                disabled={true}
                className="input"
                component={Input}
                type="text"
                validate={[required]}
                />
          </Col>
        </Form.Group>
        {user.roleName === 'user' 
          ? <Form.Group as={Row}>
              <Form.Label column sm="2" htmlFor="schoolName">School Name</Form.Label>
                <Col sm={4}>
                  <Field
                    name="schoolName"
                    disabled={true}
                    className="input"
                    component={Input}
                    type="text"
                    />
              </Col>
            </Form.Group>
          : null
        }
        
        <Form.Group as={Row}>
          <Form.Label column sm="2" htmlFor="phone">Phone</Form.Label>
            <Col sm={4}>
              <Field
                name="phone"
                className="input"
                component={Input}
                type="tel"
                />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2">Password</Form.Label>
            <Col sm={4}>
            <Button
              variant="info"
              onClick={handleShowModalUpdatePass}
                >
                Change Password
            </Button>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="2"></Form.Label>
            <Col sm={4}>
            <Button
              type="submit"
              variant="primary"
                >
                Update Profile
            </Button>
          </Col>
        </Form.Group>
      </form>
      <ModalUpdatePass
        handleUpdateUser={handleUpdateUser}
        handleClose={handleCloseModalUpdatePass}
        show={showModalUpdatePass}
      ></ModalUpdatePass>
    </div>
  );
}
ProfileForm = reduxForm({
  form: 'ProfileForm'
})(ProfileForm)

export default main(ProfileForm);