import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import Input from '../../components/Input/Input';
import Textarea from '../../components/Textarea/Textarea';
import './ContactUsForm.scss';
import { required, email } from '../../utils';

let ContactUsForm = (props) => {
  const { handleSubmit, submitting } = props;

  return (
    <div className="form-contact-us">
      <form onSubmit={handleSubmit} noValidate>
        <Form.Group>
          <Field
            name="name"
            type="text"
            placeholder="Name"
            component={Input}
          ></Field>
        </Form.Group>
        <Form.Group>
          <Field
            name="email"
            type="email"
            placeholder="Email*"
            validate={[required, email]}
            component={Input}
          ></Field>
        </Form.Group>
        <Form.Group>
          <Field
            name="message"
            type="text"
            placeholder="Message*"
            rows={10}
            maxLength={4000}
            validate={[required]}
            component={Textarea}
          ></Field>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={submitting}>
          Send
        </Button>
      </form>
    </div>
  );
};

ContactUsForm = reduxForm({
  form: 'ContactUsForm',
  initialValues: {
    name: '',
    email: '',
    message: '',
  },
})(ContactUsForm);

export default ContactUsForm;
