import React from 'react';
import main from '../../containers/main';
import { OverlayTrigger, Popover, Button} from 'react-bootstrap';
import './styles.scss';
import Notification from '../Icons/Notification/Notification';
import {REMOVE_NOTIFICATION} from '../../config/index';

const NotificationTooltip = (props) => {
  const {user, history, hideNotification, token} = props;
  const handleView = async (item, index) => {
    await (await fetch(`${REMOVE_NOTIFICATION}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user._id,
        complaint_id: item.complaint_id
      }),
    })).json();
    history.push(`/complaint-info/?id=${item.complaint_id}`);
    hideNotification(index);
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{user.notifications.length} Notifications</Popover.Title>
      <Popover.Content>
        {user.notifications.length
          ? user.notifications.map((item, index) => (
              <span key={index}>{item.message} <Button type="info" onClick={() => handleView(item, index)}> View</Button></span>
            ))
          : null
        }
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {user.notifications.length
        ? <div className="pulsating-circle"></div>
        : null
      }
      <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={popover}>
        <button className="tooltip-btn"><Notification /></button>
      </OverlayTrigger>
    </>
  );
}

export default main(NotificationTooltip);
