import React from 'react';
import DistrictsList from '../../components/DisctrictsList/DisctrictsList'
import './styles.scss';

function Districts (props) {
  return (
    <div className="container">
      <div className="row page-header">
        <h3>District List</h3>
      </div>
      <div className="row">
        <DistrictsList />
      </div>
    </div>
  );
}
export default Districts;