
import React from 'react';
import {Field} from 'redux-form';
import {required} from '../../utils';
import './styles.scss';
import main from '../../containers/main';
import { Form, Col, Row, Button } from 'react-bootstrap';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import Textarea from '../Textarea/Textarea';
import DatePicker from '../DatePicker/DatePicker';
// import TimePicker from '../TimePicker/TimePicker';
import AccusedList from '../AccusedList/AccusedList';
import WitnessList from '../WitnessList/WitnessList';
import useAccusedState from './useAccusedState';
import useWitnessState from './useWitnessState';
import {incidentNatures} from '../../utils/configData';

let IncidentForm= (props) => {
  const {number, dispatch} = props;
  const { accusedArray, addAccused, removeAccused } = useAccusedState([0]);
  const { witnessArray, addWitness, removeWitness } = useWitnessState([0]);
  
  return (
      <div className="incident">
        <Form.Group as={Row}>
          
        </Form.Group>
        <Form.Group as={Row} controlId={`incident-${number}__incidentDate`}>
          <Form.Label column sm={3}>
            Incident Date
          </Form.Label>
          <Col sm={3}>
            <Field
              name={`incident-${number}__incidentDate`}
              className="input"
              component={DatePicker}
              type="date"
              validate={[required]}
              />
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} controlId={`incident-${number}__incidentTime`}>
          <Form.Label column sm={3}>
            Incident Time
          </Form.Label>
          <Col sm={3}>
            <Field
              name={`incident-${number}__incidentTime`}
              className="input"
              component={TimePicker}
              validate={[required]}
              />
          </Col>
        </Form.Group> */}
        <Form.Group as={Row}>
          <Col className="label" sm={3}><h4>Nature of incident</h4></Col>
          <Col sm={4}></Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col className="label" sm={3}>Check why you were discriminated against</Col>
          <Col sm={9}>
            <CheckboxGroup name={`incident-${number}__nature`} options={incidentNatures}/>
          </Col>
        </Form.Group>

        <div className="complaint-form__space"></div> 

        <AccusedList dispatch={dispatch} number={number} accusedArray={accusedArray} removeAccused={removeAccused}/>

        <Form.Group as={Row}>
          <Form.Label column sm="3"></Form.Label>
          <Col sm={4}>
            <Button
              variant="primary"
              onClick={() => 
                addAccused(accusedArray.length)
              }
            >
                Add Accused
            </Button>
          </Col>
        </Form.Group>

        <div className="complaint-form__space"></div> 

        <WitnessList dispatch={dispatch} number={number} witnessArray={witnessArray} removeWitness={removeWitness}/>

        <Form.Group as={Row}>
          <Form.Label column sm="3"></Form.Label>
          <Col sm={4}>
            <Button
              variant="primary"
              onClick={() => 
                addWitness(witnessArray.length)
              }
            >
                Add Witness
            </Button>
          </Col>
        </Form.Group>

        <div className="complaint-form__space"></div> 

        <Form.Group as={Row}>
          <Form.Label column sm="3">Place of Incident</Form.Label>
          <Col sm={4}>
            <Field
              name={`incident-${number}__place`}
              type="textarea"
              className="input"
              component={Textarea}
              validate={[required]}
              rows={3}
              maxLength={1000}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">Description of Incident</Form.Label>
          <Col sm={8}>
            <Field
              name={`incident-${number}__description`}
              type="textarea"
              className="input"
              component={Textarea}
              validate={[required]}
              rows={process.env.REACT_APP_TEXT_AREA_ROWS}
              maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">Relief Sought</Form.Label>
          <Col sm={8}>
            <Field
              name={`incident-${number}__reliefSought`}
              type="textarea"
              className="input"
              component={Textarea}
              validate={[required]}
              rows={process.env.REACT_APP_TEXT_AREA_ROWS}
              maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
            />
          </Col>
        </Form.Group>
      </div>
  );
}

export default main(IncidentForm);