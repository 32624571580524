import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.scss';

const Preloader = (props) => {
 
  return (
    <div className="preloader__wrap">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};


export default Preloader;
