import {
  SET_COMPLAINTS,
  UPDATE_COMPLAINT,
  DELETE_COMPLAINT
} from '../actions/types';

const INITIAL_STATE = {
  complaints: [],
  pages: 1,
  timeline: 0
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_COMPLAINTS:
      return {
        ...state,
        complaints: action.complaintsData.complaints,
        pages: action.complaintsData.pages,
        timeline: action.complaintsData.timeline,
      }

    case UPDATE_COMPLAINT:
      return {
        ...state,
        complaints: state.complaints.map(complaint => {
          return (complaint._id === action.complaint._id)
            ? {...complaint, complaintStatus: action.complaint.status}
            : complaint
        })
      }

    case DELETE_COMPLAINT:
      return {
        ...state,
        complaints: state.complaints.filter(complaint => complaint._id !== action.complaint._id)
      }

    default:
      return state
  }
}