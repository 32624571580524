import React, { useState, useEffect } from 'react';
import main from '../../containers/main';
import {NotificationContainer} from 'react-notifications';
import './styles.scss';
import { Accordion, Card, Button } from 'react-bootstrap';

import {GET_LOGGING} from '../../config/index';

function LoggingAuditTrail(props) {
  const { setPreloader, history, token } = props;
  const [logs, setLogs] = useState(null);
  const [message, setMessage] = useState(null);
  const complaintID = new URLSearchParams(props.location.search).get("id");
  const getLogs = async () => {
    setPreloader(true)

    const response = await (await fetch(`${GET_LOGGING}?id=${complaintID}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })).json();
    if (response.success) setLogs(response.logAudit)
    else setMessage(response.message);
    setPreloader(false)

  }
  useEffect(() => {
    if (complaintID) {
      getLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="container logging-page">
      <NotificationContainer/>
      <div className="row page-header">
        <h3>Logging Audit Trail</h3>
        <Button
          variant="info"
          onClick={() => history.push(`/complaint-info/?id=${complaintID}`)}
        >
          Back
        </Button>
      </div>
      <div className="row">
        <div className="logging">
          {logs
            ? <Accordion defaultActiveKey="0">
                {logs.log.map((el, index) => (
                  <Card key={index}>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                        {el.editDate}: complaint by {el.reportData.complainantName} edited by {el.userName}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body><pre>{JSON.stringify(el.reportData, null, 2)}</pre></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))
                }
              </Accordion>
            : <h6>{message}</h6>
          }
        </div>
      </div>
    </div>
  )
}
export default main(LoggingAuditTrail);