import {Document, Paragraph, Packer, TextRun} from 'docx';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default (data) => {
  const doc = new Document();
  let content = [
    new Paragraph({children: [new TextRun(`${moment().format('MMMM DD, YYYY')}`)]}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(`VIA HAND DELIVERY & CERTIFIED MAIL`)]}),
    new Paragraph({children: [new TextRun(`${data.name}`)]}),
    new Paragraph({children: [new TextRun(`${data.address}`)]}),
    new Paragraph({children: [new TextRun(`${data.state} ${data.city}`)]}),
    new Paragraph({children: [new TextRun(`${data.zip}`)]}),
    new Paragraph({children: [new TextRun(``)]}),
    // new Paragraph({children: [new TextRun(`DASA Complaint - Outcome`)]}),
    new Paragraph({children: [new TextRun(`Pursuant to the District's Dignity for All Students Act Policy ("DASA"), I have investigated the bullying and/or harassment complaint filed regarding ${data.name}, on ${moment(data.compDate).format('MMMM DD, YYYY')} ("Complaint").`)]}),
    new Paragraph({children: [new TextRun(`The following alleged incidents were reported.`)]}),
  ];
  for (let inc in data.incidents) {
    content.push(new Paragraph({children: [new TextRun(``)]}));
    content.push(new Paragraph({children: [new TextRun(`${data.name} has alleged that on ${moment(data.incidents[inc].date).format('MMMM DD, YYYY')} at  ${data.incidents[inc].place},  ${data.incidents[inc].description}.`)]}));
    content.push(new Paragraph({children: [new TextRun(`The complainant is seeking the following corrective action :  ${data.incidents[inc].reliefSought}`)]}));
  }
  for (let inc in data.incidents) {
    content.push(new Paragraph({children: [new TextRun(``)]}));
    content.push(new Paragraph({children: [new TextRun(`As a result of the investigation, I find that:`)]}));
    content.push(new Paragraph({children: [new TextRun(`The incident of ${moment(data.incidents[inc].date).format('MMMM DD, YYYY')} is ${data.reportForm[`conclusion__incident-${+inc+1}`] ? data.reportForm[`conclusion__incident-${+inc+1}`] : 'N/A' }`)]}));
  }
  let content2 = [
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(`The conduct at issue has been addressed in accordance with the District's policy and student code of conduct.  `)]}),
    new Paragraph({children: [new TextRun(`Please be advised, retaliation for filing a DASA complaint, in any form, whether by a student, employee, or a third party acting on their behalf, is strictly prohibited by law and District Policy.  A copy of the District's DASA Policy and Regulation is enclosed, for your reference.   You are encouraged to report any conduct you feel is a violation of this Policy to me or to an appropriate supervisor.  Should you feel that you or anyone else are in any way being retaliated against, it is imperative that you report it to me as soon as possible.`)]}),
    new Paragraph({children: [new TextRun(`Please be advised that any party who is not satisfied with the outcome of the initial investigation may request a District level investigation by submitting a written complaint to the Superintendent of Schools within thirty (30) days.  `)]}),
    new Paragraph({children: [new TextRun(``)]}),
    new Paragraph({children: [new TextRun(`Sincerely,`)]}),
    new Paragraph({children: [new TextRun(`${data.userFullName}`)]}),
  ]
  for (let item of content2) content.push(item);

  doc.addSection({
    properties: {},
    children: content
  });
  Packer.toBlob(doc).then(blob => {
    saveAs(blob, "Outcome Letter to Victim.docx");
  });
}