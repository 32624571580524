import React, {useState, useEffect} from 'react';
import Input from '../Input/Input';
import main from '../../containers/main';
import {Field, reduxForm, reset} from 'redux-form';
import {required, email} from '../../utils';
import './styles.scss';
import { Form, Col, Row, Button, Accordion, Card  } from 'react-bootstrap';
import Select from '../Select/Select';
import Radio from '../Radio/Radio';
import IncidentForm from '../IncidentForm/IncidentForm';
import {typesOfRegistrar, states} from '../../utils/configData';
// import DatePicker from '../DatePicker/DatePicker';
import {GET_DISTRICT_SCHOOLS_LIST} from '../../config/index';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';

const afterSubmit = (result, dispatch) => dispatch(reset('ComplaintForm'));

const onSubmitFail = (errors, dispatch) => {
  createNotification({type: 'warning', message: `Please fill in the required fields`});
}
let ComplaintForm = (props) => {
  const { setPreloader, handleSubmit, dispatch, submitting } = props;

  const [amountIncidents, setAmountIncidents] = useState(1);
  const [schools, setSchools] = useState([]);
  let incidents = () => {
    let array = [];
    for (let inc = 1; inc <= amountIncidents; inc++) {
      array.push(
        <Card key={inc}>
          <Accordion.Toggle as={Card.Header} eventKey={inc}>
            <Col className="label center" sm={3}><h3>Incident {inc}</h3></Col>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={inc}>
            <Card.Body>
              <IncidentForm dispatch={dispatch} number={inc}  />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )
    }
    return (array)
  }
  const fetchData = async () => {
    setPreloader(true);

    await fetch(`${GET_DISTRICT_SCHOOLS_LIST}`, {
      method: 'GET',
    })
      .then(res => res.json())
      .then(data => {
        const schoolsArray = data.schools.map(val=> val.name);
        setSchools(schoolsArray);
        setPreloader(false);
      })
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normalizeNumberOfIncidents = value => {
    if (value < 1) {
      value = 1;
    } else if (value > 10) {
      value = 10;
    } else {
      // Do nothing.
    }
    setAmountIncidents(value);
    return value;
  }

  return (
    <div className="complaint-form">
      <NotificationContainer/>
      <form onSubmit={handleSubmit} noValidate>
        {/* <Form.Group as={Row} controlId="complaintDate">
          <Form.Label column sm={3}>
            Complaint Date
          </Form.Label>
          <Col sm={3}>
            <Field
              name="complaintDate"
              className="input"
              component={DatePicker}
              type="date"
              validate={[required]}
              />
          </Col>
        </Form.Group>
        
        <div className="complaint-form__space"></div>  */}
        <Form.Group as={Row}>
          <Col className="label" sm={3}><h5>Complaint Registered By:</h5></Col>
          <Col sm={4}></Col>
        </Form.Group>
        <fieldset>
          <Form.Group as={Row}>
            <Col sm={3}></Col>
            <Col sm={9}>
              <Field
                name="registeredBy"
                className="input"
                component={Radio}
                options={typesOfRegistrar}
                validate={[required]}
                />
            </Col>
          </Form.Group>
        </fieldset>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="registrarFirstName">First Name</Form.Label>
          <Col sm={4}>
            <Field
              name="registrarFirstName"
              // placeholder="Registrar First Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="registrarLastName">Last Name</Form.Label>
          <Col sm={4}>
            <Field
              name="registrarLastName"
              // placeholder="Registrar Last Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </Col>
        </Form.Group>

        <div className="complaint-form__space"></div> 
        <Form.Group as={Row}>
          <Col className="label" sm={3}><h5>Student/Victim information:</h5></Col>
          <Col sm={4}></Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="studentFirstName">First Name</Form.Label>
          <Col sm={4}>
            <Field
              name="studentFirstName"
              // placeholder="First Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="studentLastName">Last Name</Form.Label>
          <Col sm={4}>
            <Field
              name="studentLastName"
              // placeholder="Last Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="homeAddress">Home Address</Form.Label>
          <Col sm={9}>
            <Field
              name="homeAddress"
              // placeholder="Home Address"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="state">State</Form.Label>
          <Col sm={2}>
            <Field
              name="state"
              // placeholder="State"
              className="input"
              component={Select}
              options={states}
              type="select"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="city">City</Form.Label>
          <Col sm={4}>
            <Field
              name="city"
              // placeholder="City"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="zip">ZIP</Form.Label>
          <Col sm={2}>
            <Field
              name="zip"
              // placeholder="ZIP"
              className="input zip"
              component={Input}
              type="number"
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="homePhone">Home Phone</Form.Label>
          <Col sm={4}>
            <Field
              name="homePhone"
              // placeholder="Home Phone"
              className="input"
              component={Input}
              type="tel"
              validate={[]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="cellPhone">Cell Phone</Form.Label>
          <Col sm={4}>
            <Field
              name="cellPhone"
              // placeholder="Cell Phone"
              className="input"
              component={Input}
              type="tel"
              validate={[]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="workPhone">Work Phone</Form.Label>
          <Col sm={4}>
            <Field
              name="workPhone"
              // placeholder="Work Phone"
              className="input"
              component={Input}
              type="tel"
              validate={[]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="email">Email</Form.Label>
          <Col sm={4}>
            <Field
              name="email"
              // placeholder="Email"
              className="input"
              component={Input}
              type="email"
              validate={[email]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="schoolName">Student's School Name</Form.Label>
          <Col sm={4}>
            <Field
              name="schoolName"
              type="select"
              // placeholder="Email"
              className="input"
              options={schools}
              component={Select}
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="numberOfIncidents">Number Of Incidents</Form.Label>
          <Col sm={2}>
            <Field
              name="numberOfIncidents"
              className="input"
              component={Input}
              type="number"
              validate={[required]}
              normalize={normalizeNumberOfIncidents}
            />
          </Col>
        </Form.Group>
        <Accordion defaultActiveKey="0">
          {
            amountIncidents >= 1
              ? incidents()
              : null
          }
        </Accordion>
        
          
        <div className="complaint-form__space"></div>

        <div className="complaint-form__footer">
          <Button
              className="btn btn-primary submit-btn"
              type="submit"
              size="lg"
              variant="success"
              disabled={submitting}
            >
            Submit
          </Button>
        </div>     
        
      </form>
    </div>
  );
}
ComplaintForm = reduxForm({
  form: 'ComplaintForm',
  onSubmitFail,
  onSubmitSuccess: afterSubmit,
  initialValues: {
    numberOfIncidents: 1
  }
})(ComplaintForm)

export default main(ComplaintForm);