import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './styles.scss';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  // input: PropTypes.shape({
  //   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const DatePicker = (props) => {
//   const [count, setCount] = useState(0);
  const {
    className,
    input,
    meta,
    setDate,
    disabled
  } = props;

  const {touched, error, active} = meta;
  const {value} = input;

  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });

  if (setDate) input.onChange(setDate);
  
  return (
    <div className={style}>
      <div className="input__error-message">{error}</div>
      <DayPickerInput placeholder={'MM/DD/YYYY'} parseDate={parseDate} formatDate={formatDate} inputProps={{ disabled: disabled }} onDayChange={ input.onChange } selectedDay={input.value} {...input}/>
    </div>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;