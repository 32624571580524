import {connect} from 'react-redux';
import * as AuthActions from '../actions/auth';
import * as ApplicationActions from '../actions/application';
import * as DistrictActions from '../actions/districts';
import * as UserActions from '../actions/users';
import * as SchoolActions from '../actions/schools';
import * as ComplaintsActions from '../actions/complaints';
import * as ReportActions from '../actions/report';

import { getFormValues } from 'redux-form';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  roleName: state.auth.roleName,
  preloader: state.application.preloader,
  schools: state.schoolsData.schools,
  schoolPages: state.schoolsData.pages,
  districts: state.districtsData.districts,
  districtPages: state.districtsData.pages,
  users: state.usersData.users,
  userPages: state.usersData.pages,
  complaints: state.complaintsData.complaints,
  complaintPages: state.complaintsData.pages,
  timeline: state.complaintsData.timeline,
  reportForm: getFormValues('InvestigationForm')(state),
  report: state.report.report,
  districtForm: getFormValues('DistrictForm')(state),
  logout: state.application.logout,
})


const mapDispatchToProps = dispatch => ({
  signInSuccess: (token, user) => dispatch(AuthActions.signInSuccess(token, user)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
  signOut: () => dispatch(AuthActions.signOut()),
  setDistricts: districtsData => dispatch(DistrictActions.setDistricts(districtsData)),
  addDistrict: district => dispatch(DistrictActions.addDistrict(district)),
  toggleDistrict: district => dispatch(DistrictActions.toggleDistrict(district)),
  setUsers: usersData => dispatch(UserActions.setUsers(usersData)),
  toggleUser: user => dispatch(UserActions.toggleUser(user)),
  deleteUser: user => dispatch(UserActions.deleteUser(user)),
  setSchools: schoolsData => dispatch(SchoolActions.setSchools(schoolsData)),
  addSchool: school => dispatch(SchoolActions.addSchool(school)),
  setComplaints: complaintsData => dispatch(ComplaintsActions.setComplaints(complaintsData)),
  deleteComplaint: complaint => dispatch(ComplaintsActions.deleteComplaint(complaint)),
  setReport: report => dispatch(ReportActions.setReport(report)),
  setFormState: formState => dispatch(ReportActions.setFormState(formState)),
  updateComplaint: complaintData => dispatch(ComplaintsActions.updateComplaint(complaintData)),
  changeDistrictAdmin: district => dispatch(DistrictActions.changeDistrictAdmin(district)),
  changeDistrictTimeline: district => dispatch(DistrictActions.changeDistrictTimeline(district)),
  updateUser: user => dispatch(AuthActions.updateUser(user)),
  setLogout: logout => dispatch(ApplicationActions.setLogout(logout)),
  hideNotification: index => dispatch(AuthActions.hideNotification(index)),
  updateSchool: school => dispatch(SchoolActions.updateSchool(school)),
})

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
