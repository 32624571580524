import {DELETE_USER} from '../../config/index';

export default async (userId, token) => {
  const response = await fetch(`${DELETE_USER}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      userID: userId
    }),
  });
  return await response.json();
};
