import React from 'react';
import { NavLink } from 'react-router-dom';
import main from '../../containers/main';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import NotificationTooltip from '../NotificationTooltip/NotificationTooltip';
import ProfileIcon from '../Icons/Profile/Profile';
import './Navigation.scss';
import Cookies from 'universal-cookie';

const Navigation = (props) => {
  const { token, roleName, history, signOut } = props;

  const handleSignOut = (e) => {
    e.preventDefault();
    const message = window.confirm('Do you really want to log out?');
    if (message) {
      signOut();

      const cookies = new Cookies();
      const cookiesOptions = {
        domain: '.' + process.env.REACT_APP_HOST,
        path: '/',
      };

      cookies.remove('token', cookiesOptions);
      cookies.remove('roleName', cookiesOptions);
      cookies.remove('user', cookiesOptions);

      localStorage.clear();
      history.push('/login');
    }
  };

  return (
    <Navbar className="navigation justify-content-center" variant="dark">
      <Nav className="mr-auto navigation__nav-block">
        {token && roleName ? (
          <li className="navigation_item nav-item">
            <NavLink className="nav-link" to={`/profile`}>
              <ProfileIcon />
              Profile
            </NavLink>
          </li>
        ) : null}
      </Nav>
      <Nav className="ml-auto navigation__nav-block">
        {token && roleName ? <NotificationTooltip history={history} /> : null}
        {token && roleName ? (
          roleName === 'superAdmin' ? (
            <>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/districts`}>
                  Districts
                </NavLink>
              </NavItem>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/users`}>
                  Users
                </NavLink>
              </NavItem>
            </>
          ) : roleName === 'districtAdmin' ? (
            <>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/complaints`}>
                  Complaints
                </NavLink>
              </NavItem>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/schools`}>
                  Schools
                </NavLink>
              </NavItem>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/users`}>
                  Users
                </NavLink>
              </NavItem>
            </>
          ) : roleName === 'user' ? (
            <>
              <NavItem className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/complaints`}>
                  Complaints
                </NavLink>
              </NavItem>
            </>
          ) : null
        ) : null}
        {!token ? (
          <>
            <NavItem className="navigation_item nav-item">
              <NavLink className="nav-link" to={'/'}>
                Home
              </NavLink>
            </NavItem>
            <NavItem className="navigation_item nav-item">
              <NavLink className="nav-link" to={'/login'}>
                Sign In
              </NavLink>
            </NavItem>
          </>
        ) : (
          <NavItem className="navigation_item nav-item">
            <NavLink onClick={handleSignOut} className="nav-link" to="/login">
              Log out
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </Navbar>
  );
};

export default main(Navigation);
