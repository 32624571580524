import {SubmissionError} from 'redux-form';
import {SEARCH_COMPLAINT} from '../../config/index';

export default async (queryData, token) => {
  
  const response = await fetch(`${SEARCH_COMPLAINT}${queryData}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  try {
    return await response.json();
  } catch (e) {
    console.log('e: ', e);
    throw new SubmissionError(e);
  }
};
