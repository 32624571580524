import { SEND_MESSAGE } from '../../config/index';

export default async (name, email, message) => {
  const response = await fetch(`${SEND_MESSAGE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      email,
      message
    }),
  });
  return await response.json();
};
