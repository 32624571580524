import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';
// import { Form } from 'react-bootstrap';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const Radio = (props) => {
//   const [count, setCount] = useState(0);
  const {
    className,
    input,
    meta,
    options,
    col,
    disabled
  } = props;
  const {touched, error, active} = meta;
  let {value} = input;

  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });
  
  return (
    <div className={`${style} radio row`}>
      <div className="input__error-message">{error}</div>
      {options.map((val, i) => (
        typeof(val) === 'object'
          ? <div className={`col-${col ? col : 3}`} key={i}>
              <input disabled={disabled} onChange={ input.onChange }  value={val.value} checked={val.value === value} required type="radio" id={`inline-checkbox-${input.name}-${val.key}`} name={input.name}/><label htmlFor={`inline-checkbox-${input.name}-${val.key}`}>{val.key}</label>
            </div>
          : <div className={`col-${col ? col : 3}`} key={i}>
              <input disabled={disabled} onChange={ input.onChange }  value={val} required checked={val === value} type="radio" id={`inline-checkbox-${input.name}-${val}`} name={input.name}/><label htmlFor={`inline-checkbox-${input.name}-${val}`}>{val}</label>
            </div>
        ))
      }
    </div>
  );
};

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;