import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import {Field } from 'redux-form';
import './StudentGrage.scss';

const StudentGrade = (props) => {
  const {
    name,
    validate,
    disabled
  } = props;

  return (
    <Form.Group as={Row}>
      <Form.Label column sm="3">Student Grade</Form.Label>
      <Col sm={3}>
        <Field
          name={name}
          component="select"
          validate={validate}
          disabled={disabled}
        >
          <option value="kindergarten">Kindergarten</option>
          <option value="1">1st</option>
          <option value="2">2nd</option>
          <option value="3">3rd</option>
          <option value="4">4th</option>
          <option value="5">5th</option>
          <option value="6">6th</option>
          <option value="7">7th</option>
          <option value="8">8th</option>
          <option value="9">9th</option>
          <option value="10">10th</option>
          <option value="11">11th</option>
          <option value="12">12th</option>
        </Field>
      </Col>
    </Form.Group>
  );

}

export default StudentGrade;