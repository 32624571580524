import {
  SET_REPORT,
  SET_FORM_STATE
} from '../actions/types';

const INITIAL_STATE = {
  report: null,
  formState: null
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_REPORT:
      return {
        ...state,
        report: action.report,
      }
    
    case SET_FORM_STATE:
      return {
        ...state,
        formState: action.formState,
      }

    default:
      return state
  }
}