import React from 'react';
import { Row, Col } from 'react-bootstrap';
import main from '../../containers/main';
import EcmsCarousel from './EcmsCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Home.scss';
import ContactUs from './ContactUs';

const Home = (props) => {
  return (
    <>
      <div className="home container">
        <Row className="section section-header">
          <Col xs={7} className="title">
            EDUCATIONAL POLICY
            <br /> IMPLEMENTING
            <br /> CONSULTANTS
          </Col>
          <Col xs={5} className="logo">
            <img src="/images/rs=w_700,cg_true.png" alt="Logo" width="200" />
          </Col>
        </Row>
        <Row className="section section-description">
          <Col>
            <div className="text">
              Web-based Solutions for Today's Education Professionals
            </div>
            <div className="button">
              <a href="#contact-us" className="btn btn-primary">
                CONTACT US FOR A FREE DEMO OF OUR E-DIGNITY BULLYING COMPLAINT
                MANAGEMENT SYSTEM
              </a>
            </div>
          </Col>
        </Row>
        <Row className="section">
          <Col>
            <EcmsCarousel />
          </Col>
        </Row>
        <Row className="section">
          <Col>
            <h1>
              Dignity for All Students Act Compliance - FREE DEMO
              <hr />
            </h1>
            <p>
              e-Dignity, is EPIC's custom built, web-based, investigation and
              complaint management platform which provides an all-inclusive
              solution for reporting, tracking, monitoring, and investigating
              complaints of bullying and harassment.
            </p>
            <p>
              Its simple and easy-to-use interface, provides an easily
              accessible complaint form which can be customized for your school
              district and then linked to your website.
            </p>
            <p>
              Once a complaint is submitted, Administrators, Principals, and
              Dignity Act Coordinators access the web-based complaint management
              portal which guides them through every step of the investigation.
            </p>
            <p>
              From the portal, it is simple to complete customized
              acknowledgement and witness notification letters as well as
              develop an investigation report.
            </p>
          </Col>
        </Row>
        <Row className="section section-options">
          <Col>
            <h2>DASA</h2>
            <p>
              School districts are required to have policies and procedures
              giving students a real opportunity to be heard when they are being
              bullied, whether it be in the classroom, the cafeteria, the
              hallways, on a bus, a field trip, an after school event, or online
              (Cyberbullying).
            </p>
            <p>
              Cyberbullying, is the fastest growing area of concern for many
              school employees.
            </p>
          </Col>
          <Col>
            <h2>New York State Comptroller's Audit </h2>
            <p>
              We started developing e-Dignity after realizing the need for a
              cost-effective solution to the mandate that school districts
              investigate complaints of bullying and harassment.
            </p>
            <p>
              The New York State Comptroller, in a recent audit of New York
              State School Districts, reported that 31 percent of schools
              reported 0 material incidents of bullying during a three year
              period.
            </p>
            <p>The Comptroller criticized schools for:</p>
            <p>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="circle" />
                <span className="fa-layers-text fa-inverse">1</span>
              </span>
              Not accurately reporting DASA incidents or not reporting incidents
              at all
            </p>
            <p>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="circle" />
                <span className="fa-layers-text fa-inverse">2</span>
              </span>
              Not complying with record retention requirements; and
            </p>
            <p>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="circle" />
                <span className="fa-layers-text fa-inverse">3</span>
              </span>
              Not making the contact information for the district's Dignity Act
              Coordinators widely available
            </p>
            <p>
              e-Dignity is the cost-effective solution and will enable your
              district to do all this, and more.
            </p>
          </Col>
        </Row>
        <Row className="section section-services">
          <Col>
            <h1>
              Services
              <hr />
            </h1>
            <h2>Statutory Compliance </h2>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Dignity for all
              Students Complaint Management System specifically built and
              tailored to ensure compliance with your own unique policy
            </p>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> FERPA and Education
              Law 2-d compliant storage of electronic data
            </p>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> MS Windows, Apple OS,
              Tablet, and Smartphone accessible
            </p>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Multi-user platform to
              promote policy compliance
            </p>
            <br />
            <h2>Regulatory Compliance</h2>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Web-based complaint
              reporting system and database with timeline triggers,
              investigation assistance, report production and outcome letters
            </p>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Multi-user platform to
              promote policy compliance
            </p>
            <br />
            <h2>Annual Reporting </h2>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Tracking, Monitoring
              and Production of New York State VADIR and DASA incidents
              reporting requirements
            </p>
            <p>
              <FontAwesomeIcon icon="circle" size="xs" /> Maintains DASA
              complaints, reports and records to ensure compliance with record
              retention requirements (26th birthday of youngest student
              involved).
            </p>
          </Col>
        </Row>
        <Row className="section section-contact-us">
          <Col>
            <ContactUs />
          </Col>
        </Row>
        <Row className="section">
          <Col className="footer">
            Copyright © {new Date().getFullYear()} Educational Policy
            Implementing Consultants - All Rights Reserved.
          </Col>
        </Row>
      </div>
    </>
  );
};

export default main(Home);
