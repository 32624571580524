import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const PrivateRoute = ({ component: Component, access, roleName, token, isLoggedIn, ...rest }) => {
  return (
    <Route
    {...rest}
    render={(props) => (
      (localStorage.getItem('token') && access.indexOf(localStorage.getItem('roleName')) !== -1)
        ? <Component {...props}/>
        : <Redirect to="/login" />
    )}
  />
  )
}

export default PrivateRoute;