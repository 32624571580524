import React, {useState}  from 'react';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import onSubmit from './onSubmit';
import './styles.scss';
import main from '../../containers/main';
import {getSubdomain} from '../../utils';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';

let userLogin, userToken, userSchool;

const Registration = (props) => {
  const {setPreloader} = props;
  const [response, setResponse] = useState(null)

  const submit = async (prop) => {
    prop.login = userLogin;
    prop.token = userToken;
    prop.subdomain = getSubdomain();
    if (userSchool) prop.school_id = userSchool;

    setPreloader(true);
    try {
      const getResponse = await onSubmit(prop);
      setResponse(getResponse);
      if (getResponse && getResponse.success) props.history.push('/login');
      return getResponse;
    } catch (e) {
      console.error(e);
      createNotification({type: 'error', message: `Something goes wrong. Please try again or contact the support.`})
      return {};
    } finally {
      setPreloader(false);
    }
  }
  userLogin = new URLSearchParams(props.location.search).get("email");
  userToken = new URLSearchParams(props.location.search).get("token");
  userSchool = new URLSearchParams(props.location.search).get("school");
  return (
    <div className="registration container">
      <NotificationContainer/>
      <RegistrationForm onSubmit={submit} login={userLogin} response={response} ></RegistrationForm>
    </div>
  );
}

export default main(Registration);