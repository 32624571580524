import React from 'react';
import { Carousel } from 'react-bootstrap';
import './EcmsCarousel.scss';

const EcmsCarousel = () => {
  return (
    <Carousel className="ecms-carousel">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Carousel1.png"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Register Complaints</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Carousel2.png"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Investigate Complaints</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Carousel3.png"
          alt="Fourth slide"
        />
        <Carousel.Caption>
          <h3>Create Reports</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default EcmsCarousel;
