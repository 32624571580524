import React from 'react';
import './styles.scss';

let FormHint = props => {
  return (
    <ul className="hint">Password should have:
      <li>- at least 10 symbols</li>
      <li>- at least 1 capital letter</li>  
      <li>- at least 1 number</li>  
    </ul>
  );
}

export default FormHint;