import React, {useState} from 'react';
import main from '../../containers/main';
import { Container, Row, Button, ButtonToolbar, ButtonGroup, Table, Pagination } from 'react-bootstrap';
import './UsersList.scss';
import onUpdateUser from './onUpdateUser';
import {GET_USERS} from '../../config/index';
import ModalAddUser from './ModalAddUser/ModalAddUser';
import createNotification from '../Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import onSendRequestRegistration from './onSendRequestRegistration';
import onResetPassword from './onResetPassword';
import onDeleteUser from './onDeleteUser';

function UsersList (props) {
  const {
    token,
    setUsers,
    toggleUser,
    deleteUser,
    users,
    user,
    userPages,
    setPreloader,
    schools
  } = props;
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const handleCloseModalAddUser = () => setShowModalAddUser(false);
  const handleShowModalAddUser = () => setShowModalAddUser(true);
  const handleToggleEnable = async (user) => {
    await onUpdateUser({
      userID: user._id,
      updatedData: {enable: !user.enable}
    }, token);
    toggleUser(user);
  }
  const handleResetPassword = async (user) => {
    setPreloader(true);
    const response = await onResetPassword(user);
    if (response.success) {
      createNotification({type: 'success', message: response.message});
    }
    setPreloader(false);
  }

  const canDeleteUser = (user) => {
    return ['superAdmin', 'districtAdmin'].indexOf(user.roleName) === -1;
  };

  const handleDeleteUser = async (user) => {
    const isOK = window.confirm(`Do you really want to delete "${user.firstName} ${user.lastName}" user?`);
    if (isOK) {
      setPreloader(true);
      try {
        await onDeleteUser(user._id, token);
        deleteUser(user);
        createNotification({type: 'success', message: `User "${user.firstName} ${user.lastName}" has been deleted.`})
      } catch (e) {
        createNotification({type: 'error', message: `User "${user.firstName} ${user.lastName}" has not been deleted due to an unexpected error. Please contact the support.`})
      } finally {
        setPreloader(false);
      }
    }
  }

  const schoolsList = {};
  schools.forEach(item => {
    schoolsList[item._id] = item.name;
  })
  const getUsers = async (page) => {
    await fetch(`${GET_USERS}?page=${page || 1}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        setUsers(data);
      })
  }

  const handleSendRequestRegistration = async (prop) => {
    setPreloader(true);
    const response = await onSendRequestRegistration(prop, token);
    if (response.success) {
      createNotification({type: 'success', message: response.message});
    } else {
      createNotification({type: 'error', message: response.message});
    }
    setPreloader(false);
    handleCloseModalAddUser();
  }

  let pages = () => {
    let array = [];
    for (let page = 1; page <= userPages; page++) {
      array.push(
        <Pagination.Item key={page} onClick={() => getUsers(page)}>
          {page}
        </Pagination.Item>
      )
    }
    return (array)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <NotificationContainer/>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">User Type</th>
              <th scope="col">Email</th>
              {
                user && user.roleName === "districtAdmin" 
                ? <th scope="col">School</th>
                : null
              }
              <th scope="col">Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              users.length 
                ? users.map(user => (
                    <tr key={user._id}>
                      <td>{user.firstName} {user.lastName}</td>
                      <td>{user.roleName}</td>
                      <td>{user.login}</td>
                      <td>
                      {
                        user && user.roleName === "districtAdmin" 
                        ? <span>{user.school_id ? schoolsList[user.school_id] : ''}</span>
                        : null
                      }
                      </td>
                      <td>
                        {user.enable ? 'Enabled' : 'Disabled'} 
                      </td>
                      <td>
                        <ButtonToolbar>
                          <ButtonGroup className="mr-2">
                            {user.enable
                              ? <Button variant="primary" onClick={() => {handleToggleEnable(user)}}>Disable</Button>
                              : <Button variant="success" onClick={() => {handleToggleEnable(user)}}>Enable</Button>
                            }
                          </ButtonGroup>
                          <ButtonGroup className="mr-2">
                            <Button variant="info" onClick={() => {handleResetPassword(user)}}>Reset Password</Button>
                          </ButtonGroup>
                          {canDeleteUser(user) &&
                            <ButtonGroup className="mr-2">
                                <Button variant="danger" onClick={() => {handleDeleteUser(user)}}>Delete</Button>
                            </ButtonGroup>
                          }
                        </ButtonToolbar>
                      </td>
                    </tr>
                  ))
                : <tr><td>No added users yet</td></tr>
            }
          </tbody>
        </Table>
        {user && user.roleName === "districtAdmin"
          ? <Button variant="primary" onClick={()=> handleShowModalAddUser()}>
              Add User
            </Button>
          : null
        }
        {userPages > 1
            ? <Container>
                <Row className="content-footer">
                  <Pagination>{pages()}</Pagination>
                </Row>
              </Container>
            : null
        }
      </div>
      {user && user.roleName === "districtAdmin"
        ? <ModalAddUser
            handleSendRequestRegistration={handleSendRequestRegistration}
            handleClose={handleCloseModalAddUser}
            show={showModalAddUser}
          ></ModalAddUser>
        : null
      }
      
    </div>
  );
}
export default main(UsersList);