import React, {useState} from 'react';
import main from '../../containers/main';
import { Container, Row, Form, Col, Table, Pagination, ButtonToolbar, ButtonGroup, FormControl } from 'react-bootstrap';
import './ComplaintList.scss';
import { Button } from 'react-bootstrap';
import {GET_COMPLAINTS, GENERATE_REPORT} from '../../config/index';
import { complaintSearch } from '../../utils/configData';
import onSearch from './onSearch';
import moment from 'moment';
import { saveAs } from 'file-saver';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import onDeleteComplaint from './onDeleteComplaint';

function ComplaintList (props) {
  const {
    timeline,
    setPreloader,
    token,
    setComplaints,
    deleteComplaint,
    complaints,
    complaintPages,
    history,
    user
  } = props;
  const [searchSelect, setSearchSelect] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [sortedBy, setSortedBy] = useState('complaintDate');
  const [sortDate, setSortDate] = useState('asc');
  const [sortStatus, setSortStatus] = useState('asc');
  const [sortSchool, setSortSchool] = useState('asc');
  const [sortFN, setSortFN] = useState('asc');
  const [sortLN, setSortLN] = useState('asc');
  const [sortTimeline, setSortTimeline] = useState('desc');

  const handleSearch = async (event) => {
    event.preventDefault();
    setPreloader(true);
    const queryData = user.school_id ? `?by=${searchSelect}&text=${searchInput}&school_id=${user.school_id}` : `?by=${searchSelect}&text=${searchInput}`
    const getComplaints = await onSearch(queryData , token);
    if (getComplaints.success) setComplaints(getComplaints.complaintsData);
    else createNotification({type: 'info', message: getComplaints.message})
    setPreloader(false);
  }

  const getComplaintsList = (prop) => {
    setPreloader(true);

    const query = prop && prop.column 
      ? `?page=${prop.page}&sortby=${prop.column}&sortDirection=${prop.direction}${user.school_id ? `&school_id=${user.school_id}`: ''}`
      : `?page=${prop.page}${user.school_id ? `&school_id=${user.school_id}`: ''}`;

    fetch(`${GET_COMPLAINTS}${query}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) setComplaints(data.complaintsData);
        else createNotification({type: 'info', message: data.message})
        setPreloader(false);
      })
  }

  const handleResetSearch = async () => {
    setSearchSelect('');
    setSearchInput('');
    setSortedBy('complaintDate');
    await getComplaintsList({page: 1});
  }

  const handleSearchSelect= (e) => {
    setSearchSelect(e.target.value);
    setSearchInput('');
  }
  const handleSearchInput= (e) => {
    setSearchInput(e.target.value);
  }

  const handleSortBy = async (column) => {
    setSortedBy(column);
    switch (column) {
      case 'complaintDate': 
        setSortDate(sortDate === 'asc' ? 'desc' : 'asc');
        await getComplaintsList({page: 1, column, direction: sortDate});
      break
      case 'complaintStatus': 
        setSortStatus(sortStatus === 'asc' ? 'desc' : 'asc');
        await getComplaintsList({page: 1, column, direction: sortStatus});
      break
      case 'schoolName': 
        setSortSchool(sortSchool === 'asc' ? 'desc' : 'asc');
        await getComplaintsList({page: 1, column, direction: sortSchool});
      break
      case 'studentFirstName':  
        setSortFN(sortFN === 'asc' ? 'desc' : 'asc');
        await getComplaintsList({page: 1, column, direction: sortFN});
      break
      case 'studentLastName': 
        setSortLN(sortLN === 'asc' ? 'desc' : 'asc')
        await getComplaintsList({page: 1, column, direction: sortLN});
      break
      case 'timeline': 
        setSortTimeline(sortTimeline === 'asc' ? 'desc' : 'asc');
        await getComplaintsList({page: 1, column: 'complaintDate', direction: sortTimeline});
      break
      default: break
    }
  }

  const canDeleteComplaint = (complaint) => {
    return ['superAdmin', 'districtAdmin'].indexOf(user.roleName) > -1;
  };

  const handleDeleteComplaint = async (complaint) => {
    const isOK = window.confirm(`Are you sure? This complaint will be deleted without an option to restore.`);
    if (isOK) {
      setPreloader(true);
      try {
        await onDeleteComplaint(complaint._id, token);
        deleteComplaint(complaint);
        createNotification({type: 'success', message: `Complaint with ID "${formattedComplaintId(complaint)}" on "${formattedCompaintDate(complaint)}" has been deleted.`})
      } catch (e) {
        createNotification({type: 'error', message: `Complaint with ID "${formattedComplaintId(complaint)}" on "${formattedCompaintDate(complaint)}" has not been deleted due to an unexpected error. Please contact the support.`})
      } finally {
        setPreloader(false);
      }
    }
  }

  const formattedCompaintDate = complaint => {
    return moment(complaint.complaintDate).format('MM/DD/YYYY');
  }

  const formattedComplaintId = complaint => {
    const ID_PART_LENGTH = 7;
    return `${complaint._id.substr(0, ID_PART_LENGTH)}...${complaint._id.substr(complaint._id.length - ID_PART_LENGTH)}`;
  }

  let pages = () => {
    let array = [];
    for (let page = 1; page <= complaintPages; page++) {
      array.push(
        <Pagination.Item key={page} onClick={() => getComplaintsList({page})}>
          {page}
        </Pagination.Item>
      )
    }
    return (array)
  }

  const report = async () => {
    await fetch(GENERATE_REPORT, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then(async res => {
      if(res.ok){
        saveAs(new Blob([await res.arrayBuffer()], {type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), 'Reporting Form.xlsx')
      }
    });
  }
  return (
    <div className="container-fluid complaint-list">
      <NotificationContainer/>
      <div className="row">
        <form onSubmit={handleSearch} className="col-sm-12 search-form">
          <Form.Row>
            <Col sm={2} md={2} lg={1} >
              <label className="col-form-label" htmlFor="name">Search by</label>
            </Col>
            <Col sm={4} md={3} lg={2} className="select">
              {/* <Select options={complaintSearch} type="text" /> */}
              <FormControl value={searchSelect} className="input__field" as="select" onChange={handleSearchSelect} >
                <option value="" disabled >Please Select...</option>
                {complaintSearch.map(el => (
                  typeof(el) === 'object'
                    ? <option key={el.key} value={el.key}>{ el.value }</option>
                    : <option key={el} value={el}>{ el }</option>
                  
                ))}
              </FormControl>
            </Col>
            {searchSelect === 'timeline'
              ? <Col sm={4} md={3} lg={2} className="select">
                  <FormControl value={searchInput} className="input__field" as="select" onChange={handleSearchInput} >
                    <option value="" disabled >Please Select...</option>
                    <option value="overdue">Overdue</option>
                    <option value="onSchedule">On Schedule</option>
                  </FormControl>
                </Col>
              : <Col sm={4} md={3} lg={2} className="input">
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    value={searchInput}
                    onChange={handleSearchInput}
                  />
                </Col>
            }
            
            <Col sm={6} md={4} lg={4} className="btn-bar">
              <ButtonToolbar>
                <ButtonGroup className="mr-2">
                  <Button
                    variant="primary"
                    type="submit"
                    className="search-form__btn"
                  >
                    Search
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="mr-2">
                  <Button
                    variant="info"
                    onClick={handleResetSearch}
                    className="search-form__btn"
                  >
                    Reset Search
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="mr-2">
                  <Button className="search-form__btn" variant="success" onClick={report}>Download SSEC Report</Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Form.Row>
        </form>
      </div>

      <div className="row">
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th className={`sortable ${sortDate} ${sortedBy === 'complaintDate' ? 'sorted' : ''}`} onClick={() => handleSortBy('complaintDate')} scope="col">Date</th>
              <th className={`sortable ${sortStatus} ${sortedBy === 'complaintStatus' ? 'sorted' : ''}`} onClick={() => handleSortBy('complaintStatus')} scope="col">Status</th>
              <th className={`sortable ${sortSchool} ${sortedBy === 'schoolName' ? 'sorted' : ''}`} onClick={() => handleSortBy('schoolName')} scope="col">School</th>
              <th className={`sortable ${sortFN} ${sortedBy === 'studentFirstName' ? 'sorted' : ''}`} onClick={() => handleSortBy('studentFirstName')} scope="col">Student First Name</th>
              <th className={`sortable ${sortLN} ${sortedBy === 'studentLastName' ? 'sorted' : ''}`} onClick={() => handleSortBy('studentLastName')} scope="col">Student Last Name</th>
              <th className={`sortable ${sortTimeline} ${sortedBy === 'timeline' ? 'sorted' : ''}`} onClick={() => handleSortBy('timeline')} scope="col">Timeline</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              complaints.length
                ? complaints.map(complaint => (
                  <tr key={complaint._id}>
                    <td>{complaint._id}</td>
                    <td>{formattedCompaintDate(complaint)}</td>
                    <td>{complaint.complaintStatus}</td>
                    <td>{complaint.schoolName}</td>
                    <td>{complaint.studentFirstName}</td>
                    <td>{complaint.studentLastName}</td>
                    {timeline - moment().diff(moment(complaint.complaintDate), 'days') > 0 
                      ? <td>
                          {timeline - moment().diff(moment(complaint.complaintDate), 'days')}d left
                        </td>
                      : <td>
                          {timeline - moment().diff(moment(complaint.complaintDate), 'days') * -1}d overdue
                        </td>
                    }
                    
                    <td>
                      <Button className="row-btn" variant="primary" onClick={() => history.push(`/complaint-info/?id=${complaint._id}`)}>View</Button>
                      {canDeleteComplaint() &&
                        <Button className="row-btn" variant="danger" onClick={() => handleDeleteComplaint(complaint)}>Delete</Button>
                      }
                    </td>
                  </tr>
                  ))
                : <tr><td>Complaints not found</td></tr>
            }
          </tbody>
        </Table>
         
        {
          complaintPages > 1
            ? <Container>
                <Row className="content-footer">
                  <Pagination>{pages()}</Pagination>
                </Row>
              </Container>
            : null
        }
      </div>
    </div>
  );
}
export default main(ComplaintList);