import React, {useState} from 'react';
import main from '../../containers/main';
import ModalAddDistrict from './ModalAddDistrict/ModalAddDistrict';
import { Form, Container, FormControl, Col, Row, Button, ButtonToolbar, ButtonGroup, Table, Pagination } from 'react-bootstrap';
import onUpdateDistrict from './onUpdateDistrict';
import {GET_DISTRICTS, EDIT_TIMELINE} from '../../config/index';
import './styles.scss';
import ModalEdit from './ModalEdit/ModalEdit';
import createNotification from '../Notification/Notification';
import {NotificationContainer} from 'react-notifications';

function DistrictsList (props) {
  const {
    token,
    districtPages,
    setDistricts,
    toggleDistrict,
    districts,
    setPreloader,
    changeDistrictAdmin,
    changeDistrictTimeline,
  } = props;
  const [district, setDistrict] = useState('');
  const [perPage, setPerPage] = useState(10);
  const handlePerPage = async (e) => {
    setPerPage(e.target.value);
    setPreloader(true);
    await getDistricts(1, e.target.value);
    setPreloader(false);
  }
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
    setDistrict('');
  }
  const handleShowModalEdit = (dist) => {
    setShowModalEdit(true);
    setDistrict(dist);
  }

  const handleEdit = async (prop) => {
    setPreloader(true);
    if (prop.timeline !== district.timeline) {
      const response = await (await fetch(`${EDIT_TIMELINE}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({timeline: prop.timeline, subdomainName: prop.subdomainName}),
      })).json();
      if (response.success) {
        changeDistrictTimeline({subdomainName: prop.subdomainName, timeline: prop.timeline});
        createNotification({type: 'success', message: response.message});
      }
    }
    if (prop.adminEmail !== district.adminEmail) {
      await handleUpdate({_id: district._id, field: 'admin', update:{adminEmail: prop.adminEmail}});
    }
    setPreloader(false);
    handleCloseModalEdit();
  }
  const handleUpdate = async (prop) => {
    const update = await onUpdateDistrict({
      _id: prop._id,
      update: prop.update,
      token
    });
    if (update.success) {
      createNotification({type: 'success', message: 'District updated'});
      if (prop.field === 'enable') toggleDistrict({_id: prop._id, enable: prop.update.enable});
      if (prop.field === 'admin')  changeDistrictAdmin({_id: prop._id, adminEmail: prop.update.adminEmail});
    } else createNotification({type: 'error', message: update.message});
  }
  
  const getDistricts = async (page, perPage) => {
    await fetch(`${GET_DISTRICTS}?page=${page || 1}&perPage=${perPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        setDistricts(data);
      })
  }

  let pages = () => {
    let array = [];
    for (let page = 1; page <= districtPages; page++) {
      array.push(
        <Pagination.Item key={page} onClick={() => getDistricts(page, perPage)}>
          {page}
        </Pagination.Item>
      )
    }
    return (array)
  }
    
  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Subdomain</th>
              <th scope="col">Admin Email</th>
              <th scope="col">Client Name</th>
              <th scope="col">Timeline</th>
              <th scope="col">Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              districts.length 
                ? districts.map(el => (
                  <tr key={el._id}>
                    <td>{el.name}</td>
                    <td>{el.subdomainName}</td>
                    <td>{el.adminEmail}</td>
                    <td>{el.clientName}</td>
                    <td>{el.timeline}d</td>
                    <td>
                      {el.enable ? 'Enabled' : 'Disabled'} 
                    </td>
                    <td className="btn-row">
                      <ButtonToolbar>
                        <ButtonGroup className="mr-2">
                          {el.enable
                            ? <Button variant="primary" onClick={() => {handleUpdate({_id: el._id, field: 'enable', update:{enable: !el.enable}})}}>Disable</Button>
                            : <Button variant="success" onClick={() => {handleUpdate({_id: el._id, field: 'enable', update:{enable: !el.enable}})}}>Enable</Button>
                          }
                        </ButtonGroup>
                        <ButtonGroup>
                          <Button variant="info" onClick={() => {handleShowModalEdit(el)}}>Edit</Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </td>
                  </tr>
                  ))
                : <tr><td>No added districts yet</td></tr>
            }
          </tbody>
        </Table>
      
        <ModalAddDistrict></ModalAddDistrict>
        <ModalEdit
          district={district}
          handleEdit={handleEdit}
          handleClose={handleCloseModalEdit}
          show={showModalEdit}
        ></ModalEdit>

        <Container>
          <Row className="content-footer">
            <Pagination>{pages()}</Pagination>
            <Form.Group as={Col} md="2">
              <FormControl col={3} className="input__field" as="select" onChange={handlePerPage}>
                {[10, 25, 50, 100].map(el => (
                  <option key={el} value={el}>{ el }</option>
                ))}
              </FormControl>
            </Form.Group>
          </Row>
        </Container>
      </div>
        

    </div>
  );
}
export default main(DistrictsList);