import {
  SET_SCHOOLS,
  ADD_SCHOOL,
  UPDATE_SCHOOL
} from '../actions/types';

const INITIAL_STATE = {
  schools: [],
  pages: 1
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SCHOOLS:
      return {
        ...state,
        schools: action.schoolsData.schools,
        pages: action.schoolsData.pages
      }

    case ADD_SCHOOL:
      return {
        ...state,
        schools: [...state.schools, action.school],
      }

    case UPDATE_SCHOOL:
      return {
        ...state,
        schools: state.schools.map(school => {
          return (school._id === action.school._id)
            ? {...school, name: action.school.name}
            : school
        })
      }

    default:
      return state
  }
}