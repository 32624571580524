import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Field} from 'redux-form';
import './styles.scss';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const CheckboxGroup = (props) => {
  const {
    className,
    name,
    options,
    col,
    disabled
  } = props;
  
  const field = ({input, meta, options}) => {
    const {name, onChange} = input;
    const {touched, error} = meta;
    const inputValue = input.value;

    const style = classnames({
      [className]: className,
      input: true,
      input_value: inputValue,
      input_error: touched && error,
    });

    const checkboxes = options.map((value, key) => {

      const handleChange = (event) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(value);
        }
        else {
          arr.splice(arr.indexOf(value), 1);
        }
        return onChange(arr);
      };
      const checked = inputValue.includes(value);
      return (
        <div key={key} className={`col-lg-${col ? col : 3} col-md-4 col-sm-6`} >
          <input disabled={disabled} onChange={ handleChange } value={value} checked={checked} type="checkbox" id={`checkbox-${name}-${value}`} name={`${name}`}/><label htmlFor={`checkbox-${name}-${value}`}>{value}</label>
        </div>
      );
    });

    return (
      <div className={`${style} checkbox row`}>
        <div className="input__error-message">{error}</div>
        {checkboxes}
      </div>

    );
  };
 
  return <Field className={className} col={col} options={options} name={name} type="checkbox" component={field} />;
};

CheckboxGroup.propTypes = propTypes;
CheckboxGroup.defaultProps = defaultProps;

export default CheckboxGroup;