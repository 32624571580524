import {
  SET_USERS,
  TOGGLE_USER,
  DELETE_USER,
} from '../actions/types';

const INITIAL_STATE = {
  users: [],
  pages: 1
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.usersData.users,
        pages: action.usersData.pages
      }

    case TOGGLE_USER:
        return {
          ...state,
          users: state.users.map(user => {
            return (user._id === action.user._id)
              ? {...user, enable: !user.enable}
              : user
          })
        }

    case DELETE_USER:
        return {
          ...state,
          users: state.users.filter(user => user._id !== action.user._id)
        }

    default:
      return state
  }
}