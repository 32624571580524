import React, {useState, useEffect} from 'react';
import Input from '../Input/Input';
import { connect } from 'react-redux'
import main from '../../containers/main';
import { Prompt } from 'react-router';
import {Field, reduxForm, initialize } from 'redux-form';
import './InvestigationForm.scss';
import { Form, Col, Row, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Select from '../Select/Select';
import Radio from '../Radio/Radio';
import {
  incidentDescription,
  conclusion,
  remidialActions,
  incidentConstitutes,
  incidentPlace,
  offender,
  incidentTime} from '../../utils/configData';
import DatePicker from '../DatePicker/DatePicker';
import Textarea from '../Textarea/Textarea';
import generateVL from '../../utils/generateVictimLetter';
import generateAL from '../../utils/generateAccusedLetter';
// import generateWL from '../../utils/generateWitnessLetter';
import generateW from '../../utils/generateWitness';
import generateIR from '../../utils/generateInvestigationReport';
import moment from 'moment';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import StudentGrade from '../StudentGrade/StudentGrade';

let InvestigationForm = (props) => {
  const {
    reportForm,
    history,
    logout,
    report, 
    roleName,
    setFormState,
    setReport,
    setLogout,
    user,
    setPreloader,
    complaintData,
    handleSubmit,
    pristine,
    dirty,
    submitting,
    signOut
  } = props;

  const [countRemedials, setCountRemedials] = useState(0);
  const [disableFields, setDisableFields] = useState(false);
  let initialVal = {};

  const generateVictimLetter = (incidentNumber) => {
    const letterData = {
      name: `${complaintData.studentFirstName} ${complaintData.studentLastName}`,
      address: complaintData.homeAddress,
      compDate: complaintData.complaintDate,
      state: complaintData.state,
      city: complaintData.city,
      zip: complaintData.zip,
      incidents: complaintData.incidents,
      reliefSought: complaintData.reliefSought,
      userFullName: `${user.firstName} ${user.lastName}`,
      reportForm
    }
    generateVL(letterData);
  }

  const generateWitnessVictim = (data) => {
    const letterData = {
      name: `${data.studentFirstName} ${data.studentLastName}`,
      address: data.homeAddress,
      state: data.state,
      city: data.city,
      zip: data.zip,
      userFullName: `${user.firstName} ${user.lastName}`,
    }
    generateW(letterData);
  }

  const generateWitness = (data) => {
    const letterData = {
      name: data.name,
      address: null,
      userFullName: `${user.firstName} ${user.lastName}`,
    }
    generateW(letterData);
  }

  const generateAccusedLetter = (data) => {
    const {incident, name, incNumber} = data;
    const letterData = {
      name,
      compDate: complaintData.complaintDate,
      incident,
      incNumber,
      reportForm,
      userFullName: `${user.firstName} ${user.lastName}`
    }
    generateAL(letterData);
  }

  // const generateWitnessLetter = (data) => {
  //   const { name } = data;
  //   const letterData = {
  //     name,
  //     compDate: complaintData.complaintDate,
  //     incidents: complaintData.incidents,
  //     reliefSought: complaintData.reliefSought,
  //     userFullName: `${user.firstName} ${user.lastName}`,
  //     reportForm
  //   }
  //   generateWL(letterData);
  // }

  window.onbeforeunload = function() {
    return false;
  };

  const generateInvestigationReport = (data) => {
    generateIR({
      reportForm,
      complaintData,
      userFullName: `${user.firstName} ${user.lastName}`
    });
  }

  useEffect(() => {
    if (report && report.incidents) {
      setPreloader(true);
      for (let item in report) {
        if (item !== 'incidents') {
          item.indexOf('reportDate') !== -1
            ? initialVal[item] = moment(report[item]).format('MM/DD/YYYY')
            : initialVal[item] =  report[item];
        }
      }
      report.incidents.forEach((incident, index) => {
        for (let item in incident) {
          if (item !== 'accused' && item !== 'witness') {
            item.indexOf('InterviewedOn') !== -1
              ? initialVal[`${item}__incident-${index + 1}`] = moment(incident[item]).format('MM/DD/YYYY')
              : initialVal[`${item}__incident-${index + 1}`] =  incident[item];
            
          }
        }
        incident.accused.forEach((accused, ind) => {
          for (let item in accused) {
            item.indexOf('InterviewedOn') !== -1
              ? initialVal[`${item}-${ind + 1}__incident-${index + 1}`] =  moment(accused[item]).format('MM/DD/YYYY')
              : initialVal[`${item}-${ind + 1}__incident-${index + 1}`] = accused[item];
            
          }
        })
        incident.witness.forEach((witness, ind) => {
          for (let item in witness) {
            item.indexOf('InterviewedOn') !== -1
              ? initialVal[`${item}-${ind + 1}__incident-${index + 1}`] = moment(witness[item]).format('MM/DD/YYYY')
              : initialVal[`${item}-${ind + 1}__incident-${index + 1}`] = witness[item];
          }
        })
      })
      setPreloader(false);
    } else {
      initialVal['complainantName'] = `${complaintData['registrarFirstName']} ${complaintData['registrarLastName']}`;
      initialVal['victimName'] = `${complaintData['studentFirstName']} ${complaintData['studentLastName']}`;
      
      complaintData.incidents.forEach((incident, index) => {
        initialVal[`incidentId__incident-${index + 1}`] = incident._id;
        incident.accused.forEach((accused, ind) => {
          initialVal[`accusedName-${ind + 1}__incident-${index + 1}`] = `${accused['accusedFirstName']} ${accused['accusedLastName']}`;
        })
        incident.witness.forEach((witness, ind) => {
          initialVal[`witnessName-${ind + 1}__incident-${index + 1}`] = `${witness['witnessFirstName']} ${witness['witnessLastName']}`;
        })
      })
    }
    setFormState(initialVal);
    return () => {
      setFormState({});
      setReport(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveAndLogout = async () => {
    await handleSubmit();
    signOut();
    history.push('/login');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    let timer = setInterval(async() => {
      if (dirty) {
        await handleSubmit()
      }
    }, 60 * 1000);

    return function cleanup() {
      clearInterval(timer);
    }
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (logout && !pristine) {
      setLogout(false);
      saveAndLogout();
    } else if (logout && pristine) {
      signOut();
      history.push('/login');
    }
    let counter = 0;
    if (reportForm && reportForm.status === 'Closed') setDisableFields(true); 
    else setDisableFields(false);  

    for (let item in reportForm) {
      if (item.indexOf('remedialActions') !== -1) counter++;
    }
    setCountRemedials(counter);
  })

  const getAccusedLetterButtonCaption = (incidents, incidentIndex, accuseds, accusedIndex) => {
    return "Generate " +
      (incidents.length > 1 ? "Incident-" + (incidentIndex + 1) + " " : "") +
      (accuseds.length > 1 ? "Accused-" + (accusedIndex + 1) + " " : "Accused ") +
      "Outcome Letter ";
  }

  return (
    <div className="investigation-form">
      {dirty
        ? <Prompt message="You have entered new data on this page. If you leave the page without saving your data first, the changes will be lost." />
        : null
      }
      {complaintData 
        ? <>
            <form onSubmit={handleSubmit} noValidate>
              <Form.Group as={Row} controlId="reportDate">
                <Form.Label column sm={3}>
                  Date of Report
                </Form.Label>
                <Col sm={3}>
                  <Field
                    name="reportDate"
                    className="input"
                    component={DatePicker}
                    type="date"
                    validate={[]}
                    disabled={disableFields}
                    />
                </Col>
              </Form.Group>

              <StudentGrade name="studentGrade" disabled={disableFields}></StudentGrade>

              <Form.Group as={Row}>
                <Form.Label column sm="3"></Form.Label>
                <Col sm={8} md={6}>
                  <ButtonToolbar>
                    <ButtonGroup className="mr-2">
                      <Button variant="info" onClick={() => generateWitnessVictim(complaintData)}>Generate Victim Interview Letter</Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Form.Group>
              {complaintData.incidents.length
                ? complaintData.incidents.map((inc, index) => (
                  <div key={index}>
                    <hr />
                    <h3>Incident-{index+1}</h3>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Incident ID</Form.Label>
                      <Col sm={8} md={4}>
                        <Field
                          name={`incidentId__incident-${index+1}`}
                          className="input"
                          component={Input}
                          type="text"
                          validate={[]}
                          disabled
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Incident Complainant Name</Form.Label>
                      <Col sm={8} md={4}>
                        <Field
                          name="complainantName"
                          className="input"
                          component={Input}
                          type="text"
                          validate={[]}
                          data={`${complaintData.registrarFirstName} ${complaintData.registrarLastName}`}
                          disabled
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Complainant Interviewed on</Form.Label> 
                      <Col sm={8} md={4}>
                        <Field
                          name={`complainantInterviewedOn__incident-${index+1}`}
                          className="input"
                          component={DatePicker}
                          type="text"
                          validate={[]}
                          disabled={disableFields}
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">What did the {complaintData.registrarFirstName} {complaintData.registrarLastName} say?</Form.Label>
                      <Col sm={8}>
                        <Field
                          name={`complainantSaid__incident-${index+1}`}
                          className="input"
                          component={Textarea}
                          type="textarea"
                          rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                          maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                          validate={[]}
                          disabled={disableFields}
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Notes</Form.Label>
                      <Col sm={8}>
                        <Field
                          name={`complainantNote__incident-${index+1}`}
                          className="input"
                          component={Textarea}
                          type="textarea"
                          rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                          maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                          validate={[]}
                          disabled={disableFields}
                          />
                      </Col>
                    </Form.Group>

                    <hr />
                    <h5>The following Victim was interviewed as part of this investigation:</h5>
                    <div >
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">Victim Name</Form.Label>
                        <Col sm={8} md={4}>
                          <Field
                            name={`victimName`}
                            className="input"
                            component={Input}
                            type="text"
                            validate={[]}
                            data={`${complaintData.studentFirstName} ${complaintData.studentLastName}`}
                            disabled={disableFields}
                            />
                        </Col>
                      </Form.Group>
                      
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">Victim Interviewed on</Form.Label>
                        <Col sm={8} md={4}>
                          <Field
                            name={`victimInterviewedOn__incident-${index+1}`}
                            className="input"
                            component={DatePicker}
                            type="text"
                            validate={[]}
                            disabled={disableFields}
                            />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">What did {complaintData.studentFirstName} {complaintData.studentLastName} say?</Form.Label>
                        <Col sm={8}>
                          <Field
                            name={`victimSaid__incident-${index+1}`}
                            className="input"
                            component={Textarea}
                            type="textarea"
                            rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                            maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                            validate={[]}
                            disabled={disableFields}
                            />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">Notes</Form.Label>
                        <Col sm={8}>
                          <Field
                            name={`victimNote__incident-${index+1}`}
                            className="input"
                            component={Textarea}
                            type="textarea"
                            rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                            maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                            validate={[]}
                            disabled={disableFields}
                            />
                        </Col>
                      </Form.Group>
                      <div className="complaint-form__space"></div> 
                    </div> 
                  

                    <hr />
                    <h5>The following Accused were interviewed as part of this investigation:</h5>
                    {inc.accused.length
                      ? inc.accused.map((accused, ind) => (
                        <div key={ind}>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Accused Name</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`accusedName-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Input}
                                type="text"
                                validate={[]}
                                data={`${accused.accusedFirstName} ${accused.accusedLastName}`}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3"></Form.Label>
                            <Col sm={8} md={6}>
                              <ButtonToolbar>
                                <ButtonGroup className="mr-2">
                                  <Button variant="info" onClick={() => generateWitness({
                                      name: `${accused.accusedFirstName} ${accused.accusedLastName}`
                                    })}>
                                    Generate Accused Interview Letter
                                  </Button>
                                </ButtonGroup>
                              </ButtonToolbar>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Accused Interviewed on</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`accusedInterviewedOn-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={DatePicker}
                                type="text"
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">What did {accused.accusedFirstName} {accused.accusedLastName} say?</Form.Label>
                            <Col sm={8}>
                              <Field
                                name={`accusedSaid-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Textarea}
                                type="textarea"
                                rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                                maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Notes</Form.Label>
                            <Col sm={8}>
                              <Field
                                name={`accusedNote-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Textarea}
                                type="textarea"
                                rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                                maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <div className="complaint-form__space"></div> 
                        </div> 
                      ))
                      : null
                    }
                    
                    <hr />
                    <h5>The following Witness was interviewed as part of this investigation:</h5>
                    {inc.witness.length
                      ? inc.witness.map((witness, ind) => (
                        <div key={ind}>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Witness Name</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`witnessName-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Input}
                                type="text"
                                validate={[]}
                                data={`${witness.witnessFirstName} ${witness.witnessLastName}`}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3"></Form.Label>
                            <Col sm={8} md={6}>
                              <ButtonToolbar>
                                <ButtonGroup className="mr-2">
                                  <Button variant="info" onClick={() => generateWitness({
                                      name: `${witness.witnessFirstName} ${witness.witnessLastName}`
                                    })}>
                                    Generate Witness Interview Letter
                                  </Button>
                                </ButtonGroup>
                              </ButtonToolbar>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Witness Interviewed on</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`witnessInterviewedOn-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={DatePicker}
                                type="text"
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">What did {witness.witnessFirstName} {witness.witnessLastName} say?</Form.Label>
                            <Col sm={8}>
                              <Field
                                name={`witnessSaid-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Textarea}
                                type="textarea"
                                rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                                maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Notes</Form.Label>
                            <Col sm={8}>
                              <Field
                                name={`witnessNote-${ind+1}__incident-${index+1}`}
                                className="input"
                                component={Textarea}
                                type="textarea"
                                rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                                maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <div className="complaint-form__space"></div> 
                        </div> 
                      ))
                      : null
                    }
                    
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Conclusion for Incident</Form.Label>
                      <Col sm={8} md={5}>
                        <Field
                          name={`conclusion__incident-${index+1}`}
                          className="input"
                          component={Radio}
                          col={4}
                          options={conclusion}
                          validate={[]}
                          disabled={disableFields}
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Notes</Form.Label>
                      <Col sm={8}>
                        <Field
                          name={`conclusionNote__incident-${index+1}`}
                          className="input"
                          component={Textarea}
                          type="textarea"
                          rows={process.env.REACT_APP_TEXT_AREA_ROWS}
                          maxLength={process.env.REACT_APP_TEXT_AREA_MAX_LENGTH}
                          validate={[]}
                          disabled={disableFields}
                          />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">Remedial Actions</Form.Label>
                      <Col sm={8} md={8}>
                        <CheckboxGroup disabled={disableFields} name={`remedialActions__incident-${index+1}`} options={remidialActions}/> 
                      </Col>
                    </Form.Group>
                    {reportForm && reportForm[`conclusion__incident-${index+1}`] === 'Founded'
                      ? <>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">(Founded) Incident Constitutes</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`incidentConstitutes__incident-${index+1}`}
                                className="input"
                                component={Select}
                                type="select"
                                options={incidentConstitutes}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Where Incident Occurred</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`whereIncidentOccurred__incident-${index+1}`}
                                className="input"
                                component={Select}
                                type="select"
                                options={incidentPlace}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">When Incident Occurred</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`whenIncidentOccurred__incident-${index+1}`}
                                className="input"
                                component={Select}
                                type="select"
                                options={incidentTime}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">How Incident Occurred</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`howIncidentOccurred__incident-${index+1}`}
                                className="input"
                                component={Select}
                                type="select"
                                options={incidentDescription}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Involving a Weapon</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`weapon__incident-${index+1}`}
                                className="input"
                                component={Radio}
                                options={[{key: 'Yes', value: 'true'}, {key: 'No', value: 'false'}]}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Involving Alcohol</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`alcohol__incident-${index+1}`}
                                className="input"
                                component={Radio}
                                options={[{key: 'Yes', value: 'true'}, {key: 'No', value: 'false'}]}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Involving Drugs</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`drugs__incident-${index+1}`}
                                className="input"
                                component={Radio}
                                options={[{key: 'Yes', value: 'true'}, {key: 'No', value: 'false'}]}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">Who Was the Offender</Form.Label>
                            <Col sm={8} md={4}>
                              <Field
                                name={`offenders__incident-${index+1}`}
                                className="input"
                                component={Select}
                                type="select"
                                options={offender}
                                validate={[]}
                                disabled={disableFields}
                                />
                            </Col>
                          </Form.Group>
                        </>
                      : null
                    }
                    
                    
                  </div>
                ))
                : null
              }
              <hr />
              <Form.Group as={Row}>
                <Form.Label column sm="3">Complaint Status</Form.Label>
                <Col sm={8} md={4}>
                  <Field
                    name={`status`}
                    className="input"
                    component={Select}
                    type="select"
                    disabled={countRemedials !== complaintData.incidents.length}
                    options={[{key: 'Opened', value: 'Opened'}, {key: 'Closed', value: 'Closed'}]}
                    validate={[]}
                    />
                </Col>
              </Form.Group>
              
              {reportForm && reportForm.status === 'Closed' &&
              <>
                <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm="3">Outcome Letters</Form.Label>
                  <Col sm={8}>
                    <ButtonToolbar>
                      <Button variant="info" className="mb-2 mr-2" onClick={() => generateVictimLetter()}>Generate Victim Outcome Letter</Button>
                      {complaintData.incidents.length 
                        ? complaintData.incidents.map((incident, incidentIndex) => (
                          incident.accused.length
                            ? incident.accused.map((accused, accusedIndex) => (
                              <Button key={incidentIndex + "." + accusedIndex} variant="info" className="mb-2 mr-2" onClick={() => generateAccusedLetter({
                                name: `${accused.accusedFirstName} ${accused.accusedLastName}`,
                                incident: incident,
                                incNumber: incidentIndex + 1
                              })}>
                                {getAccusedLetterButtonCaption(complaintData.incidents, incidentIndex, incident.accused, accusedIndex)}
                              </Button>
                            ))
                            :null
                        ))
                        : null
                      }
                    </ButtonToolbar>
                  </Col>
                </Form.Group>
              </>
              }

              <div className="complaint-form__space"></div> 
              
              <div className="complaint-form__footer">
                <ButtonToolbar>
                  <ButtonGroup className="mr-2">
                    <Button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        size="lg"
                        variant="success"
                        disabled={pristine || submitting}
                      >
                      Save Report
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="mr-2">
                    <Button
                        className="btn btn-primary submit-btn"
                        size="lg"
                        variant="primary"
                        onClick={() => generateInvestigationReport()}
                      >
                      Download Report
                    </Button>
                  </ButtonGroup>
                  {roleName === 'districtAdmin'
                    ? <ButtonGroup className="mr-2">
                    <Button
                        className="btn submit-btn"
                        size="lg"
                        variant="info"
                        onClick={() => history.push(`/logging/?id=${complaintData._id}`)}
                      >
                      Logging Audit Trail
                    </Button>
                  </ButtonGroup>
                    : null
                  }
                </ButtonToolbar>
              </div>     
              
            </form>
          </>
        : null
      }
      
    </div>
  );
}
InvestigationForm = reduxForm({
  form: 'InvestigationForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(initialize('InvestigationForm', props.values, { keepSubmitSucceeded: true }))
  }
})(InvestigationForm)
InvestigationForm = connect(
  state => ({
    initialValues: state.report.formState,
  })
)(InvestigationForm)
export default main(InvestigationForm);