import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';

import './styles.scss';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const Textarea = (props) => {
//   const [count, setCount] = useState(0);
  const {
    className,
    placeholder,
    input,
    meta,
    disabled,
    type,
    rows,
    maxLength
  } = props;
  

  const {touched, error, active} = meta;
  const {value} = input;

  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });
  
  return (
    <div className={style}>
      <div className="input__error-message">{error}</div>
      <Form.Control
        className="input__field form-control"
        as="textarea"
        placeholder={placeholder}
        type={type}
        {...input}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        />
    </div>
  );
};

Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;

export default Textarea;