import React, {useState} from 'react';
import DistrictForm from '../../DistrictForm/DistrictForm';
import { Modal, Button } from 'react-bootstrap';
import main from '../../../containers/main';
import onSubmit from './onSubmit';
import createNotification from '../../Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import './styles.scss';


const DistrictModal = (props) => {
  const {token, addDistrict, setPreloader } = props;
  const submit = async (prop) => {
    setPreloader(true);
    prop.enable = true;
    const getResponse = await onSubmit({prop, token});
    if (getResponse && getResponse.success) {
      createNotification({type: 'success', message: 'New District Сreated'});
      addDistrict(getResponse.district);
      handleClose();
    } else {
      createNotification({type: 'error', message: getResponse.message});
    }
    setPreloader(false);
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <NotificationContainer/>
      <Button variant="primary" onClick={handleShow}>
        Add District
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>District Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DistrictForm onSubmit={submit}></DistrictForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default main(DistrictModal);