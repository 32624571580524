import React, {useState ,useEffect} from 'react';
import Input from '../Input/Input';
import main from '../../containers/main';
import {Field, reduxForm, change} from 'redux-form';
import {required, email} from '../../utils';
import { Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import {RESEND_INVITATION} from '../../config/index';
import './styles.scss';
import createNotification from '../Notification/Notification';
import {NotificationContainer} from 'react-notifications';

let DistrictForm = (props) => {
  const { handleSubmit, setPreloader, users, handleClose, editMode, district, dispatch, token } = props;
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    if (district) {
      dispatch(change('DistrictForm', 'name', district.name));
      dispatch(change('DistrictForm', 'clientName', district.clientName));
      dispatch(change('DistrictForm', 'adminEmail', district.adminEmail));
      dispatch(change('DistrictForm', 'subdomainName', district.subdomainName));
      dispatch(change('DistrictForm', 'timeline', district.timeline));
    }
  }, [dispatch, district])

  const handleResendInvitation = async () => {
    setPreloader(true);
    const resendInvitationResponse = await (await fetch(`${RESEND_INVITATION}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        login: district.adminEmail,
        subdomainName: district.subdomainName
      }),
    })).json();
    if (resendInvitationResponse.success) createNotification({type: 'success', message: resendInvitationResponse.message});
    else createNotification({type: 'error', message: 'Error'});
    setPreloader(false);
    handleClose();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUserExists = () => {
    for (let item of users) {
      if (item.login === district.adminEmail) {
        setUserExists(true);
        break;
      }
    }
  }
  useEffect(() => {
    if (district) {
      checkUserExists();
    }
  },[district, checkUserExists])
  return (
    <div className="district-form">
      <NotificationContainer/>
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">District Name</label>
          <div className="col-sm-8">
            <Field
              name="name"
              className="input"
              component={Input}
              type="text"
              disabled={editMode}
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="clientName">Client Name</label>
          <div className="col-sm-8">
            <Field
              name="clientName"
              disabled={editMode}
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="adminEmail">Email</label>
          <div className="col-sm-8">
            <Field
              name="adminEmail"
              className="input"
              component={Input}
              type="email"
              validate={[required, email]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="subdomainName">Subdomain</label>
          <div className="col-sm-8">
            <Field
              name="subdomainName"
              disabled={editMode}
              className="input"
              component={Input}
              type="subdomain"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="timeline">Timeline</label>
          <div className="col-sm-8">
            <Field
              name="timeline"
              className="input"
              component={Input}
              type="number"
              validate={[required]}
              />
          </div>
        </div>
        <ButtonToolbar>
          <ButtonGroup className="mr-2">
            <Button
              variant="primary"
              type="submit"
              >
              Submit
            </Button>
          </ButtonGroup>
          {!userExists && district
            ? <ButtonGroup className="mr-2">
                <Button
                  variant="info"
                  onClick={handleResendInvitation}
                  >
                  Resend Invitation
                </Button>
              </ButtonGroup>
            : null
          }
          </ButtonToolbar>
      </form>
    </div>
  );
}
DistrictForm = reduxForm({
  form: 'DistrictForm'
})(DistrictForm)

export default main(DistrictForm);