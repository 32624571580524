import React, { useState } from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { Button as ButtonB, ButtonToolbar, Col } from 'react-bootstrap';

import {Field, reduxForm} from 'redux-form';
import {required, email} from '../../utils';
import './styles.scss';
import Modal from './Modal/Modal';

let LoginForm = (props) => {
  const { handleSubmit, response } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="login-form col-lg-6">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate>
        <div className="form-group row ">
          <label className="col-sm-4 col-form-label" htmlFor="login">Login</label>
          <div className="col-sm-8">
            <Field
              name="login"
              className="input"
              component={Input}
              type="email"
              validate={[required, email]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label  className="col-sm-4 col-form-label" htmlFor="password">Password</label>
          <div className="col-sm-8">
            <Field
              name="password"
              className="input"
              component={Input}
              type="password"
              validate={[required]}
              />
          </div>
        </div>
        
          <ButtonToolbar>
              <Col md="6">
                <Button
                  className="btn btn-primary submit-btn"
                  type="submit"
                >
                  Sign In
                </Button>
              </Col> 
              <Col md="6">
                <ButtonB variant="primary" onClick={handleShow}>
                  Forgot Password
                </ButtonB>
              </Col> 
          </ButtonToolbar>

        {response && !response.success
          ? <div className="alert alert-danger" role="alert">
              {response.message}
            </div>
          : null
        }
      </form>
      <Modal show={show} handleClose={handleClose}></Modal>
    </div>
  );
}

LoginForm = reduxForm({
  form: 'loginForm'
})(LoginForm)

export default LoginForm;