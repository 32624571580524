import React, {useEffect} from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import main from '../../containers/main';
import {Field, reduxForm,change} from 'redux-form';
import {required} from '../../utils';
import './styles.scss';

let SchoolForm = (props) => {
  const { handleSubmit, dispatch, school } = props;
  useEffect(() => {
    if (school) {
      dispatch(change('SchoolForm', 'name', school.name));
    }
  }, [dispatch, school])
  return (
    <div className="school-form">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">School Name</label>
          <div className="col-sm-8">
            <Field
              name="name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        
        <Button
            className="btn btn-primary submit-btn"
            type="submit"
          >
          Submit
        </Button>
      </form>
    </div>
  );
}
SchoolForm = reduxForm({
  form: 'SchoolForm'
})(SchoolForm)

export default main(SchoolForm);