  const incidentNatures = [
  'Race',
  'Color',
  'Disability',
  'Sex',
  'Ethnic Group',
  'Religion',
  'Gender',
  'Weight',
  'National Origin',
  'Religious Practices',
  'Sexual Orientation',
  'Other'
];

const complaintSearch = [
  {key:"_id", value: "Complaint ID"},
  // {key:"complaintDate", value: "Complaint Date"},
  {key:"studentFirstName", value: "Student First Name"},
  {key:"studentLastName", value: "Student Last Name"},
  {key:"timeline", value: "Timeline"},
];

const conclusion = [
  "Founded",
  "Unfounded",
  "Insufficient Evidence"
];

const remidialActions = [
  "None were required",
  "The incident did not warrant any corrective action",
  "Student conference",
  "Letter of apology",
  "Student warning",
  "Out-of- school suspension",
  "Peer Mediation",
  "Counseling",
  "Parent letter",
  "Parent phone call",
  "Parent conference",
  "Detention",
  "In-school suspension",
  "Expulsion",
  "Involuntary Transfer to an Alternative Placement",
  "Teacher Removal",
  "Community Service",
  "Juvenile Justice Or Criminal Justice System",
  "Law Enforcement"
];

const incidentConstitutes = [
  "Discrimination/Harassment",
  "Cyberbullying"
];

const incidentPlace = [
  "On School Property",
  "At School Function Off Grounds",
  "School Transportation",
  "Off School Property"
];

const incidentTime = [
  "During Regular School Hours",
  "Outside of Regular School Hours"
];

const incidentDescription = [
  "Intimidation or abuse but no verbal threat or physical contact",
  "Verbal threat but no physical contact",
  "Physical contact but no verbal threat",
  "Verbal threat and physical contact",
  "Other"
];

const offender = [
  "Only student offenders",
  "Only employee offenders",
  "Both student and employee offenders",
  "Other"
];

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
]

const typesOfRegistrar = [
  'Student',
  'Parent',
  'Other',
];

const typesOfTitle = [
  'Student',
  'Administrator',
  'Teacher',
  'Other'
]

export {
  incidentNatures,
  typesOfRegistrar,
  typesOfTitle,
  states,
  complaintSearch,
  incidentDescription,
  conclusion,
  remidialActions,
  incidentConstitutes,
  incidentPlace,
  incidentTime,
  offender
}
