import React, {useState} from 'react';
import main from '../../containers/main';
import { Container, Row, Table, Pagination } from 'react-bootstrap';
import './styles.scss';
import { Button } from 'react-bootstrap';
import {GET_SCHOOLS} from '../../config/index';
import ModalAddSchool from './ModalAddSchool/ModalAddSchool';
import ModalEditSchool from './ModalEditSchool/ModalEditSchool';
import onUpdateSchool from './onUpdateSchool';

function SchoolsList (props) {
  const {
    token,
    setSchools,
    schools,
    schoolPages,
    setPreloader,
    updateSchool
  } = props;
  const [school, setSchool] = useState(null);
  const [showModalAddSchool, setShowModalAddSchool] = useState(false);
  const handleCloseModalAddSchool = () => setShowModalAddSchool(false);
  const handleShowModalAddSchool = () => setShowModalAddSchool(true);
  const [showModalEditSchool, setShowModalEditSchool] = useState(false);
  const handleCloseModalEditSchool = () => setShowModalEditSchool(false);
  const handleShowModalEditSchool = () => setShowModalEditSchool(true);
  const getSchools = async (page) => {
    setPreloader(true);
    const jsonData = await (await fetch(`${GET_SCHOOLS}?page=${page || 1}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })).json();
    if (jsonData.success) {
      setSchools(jsonData.schoolsData);
    }
    setPreloader(false);
  }
  const handleEdit = school => {
    setSchool(school);
    handleShowModalEditSchool();
  }

  const handleUpdateSchool = async (prop) => {
    setPreloader(true);
    const response = await onUpdateSchool({_id: school._id, name: prop.name}, token);
    if (response.success) {
      updateSchool({_id: school._id, name: prop.name});
    }
    setSchool(null);
    //   createNotification({type: 'success', message: response.message});
    // } else {
    //   createNotification({type: 'error', message: response.message});
    // }
    setPreloader(false);
    handleCloseModalEditSchool();
  }
  let pages = () => {
    let array = [];
    for (let page = 1; page <= schoolPages; page++) {
      array.push(
        <Pagination.Item key={page} onClick={() => getSchools(page)}>
          {page}
        </Pagination.Item>
      )
    }
    return (array)
  }

  return (
    <div className="container-fluid school-list">
      <div className="row">
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {
              schools.length
                ? schools.map(el => (
                  <tr key={el._id}>
                    <td>{el.name}</td>
                    <td className="school-list__action"><Button variant="primary" onClick={() => {handleEdit(el)}}>Edit</Button></td>
                    
                  </tr>
                  ))
                : <tr><td>No added schools yet</td></tr>
            }
          </tbody>
        </Table>
        <Button variant="primary" onClick={()=> handleShowModalAddSchool()}>
          Add School
        </Button>
        {
          schoolPages > 1
            ? <Container>
                <Row className="content-footer">
                  <Pagination>{pages()}</Pagination>
                </Row>
              </Container>
            : null
        }
      </div>
      
      <ModalAddSchool
        handleClose={handleCloseModalAddSchool}
        show={showModalAddSchool}
      ></ModalAddSchool>
      <ModalEditSchool
        school={school}
        handleUpdateSchool={handleUpdateSchool}
        handleClose={handleCloseModalEditSchool}
        show={showModalEditSchool}
      ></ModalEditSchool>
    </div>
  );
}
export default main(SchoolsList);