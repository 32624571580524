import React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import './ContactUs.scss';
import ContactUsForm from './ContactUsForm';
import main from '../../containers/main';
import onSendMessage from './onSendMessage';
import createNotification from '../../components/Notification/Notification';
import { NotificationContainer } from 'react-notifications';

const ContactUs = (props) => {
  const { setPreloader } = props;

  const [isContactFormVisible, setContactFormVisible] = useState(false);
  const [isContactFormSubmitted, setContactFormSubmitted] = useState(false);

  const onShowForm = () => {
    setContactFormVisible(true);
  };

  const onSubmitForm = async (data) => {
    const { name, email, message } = data;

    setPreloader(true);
    try {
      await onSendMessage(name, email, message);
      setContactFormVisible(false);
      setContactFormSubmitted(true);
    } catch (e) {
      createNotification({
        type: 'error',
        message: `Message has not been sent due to an unexpected error. Please contact the support.`,
      });
    } finally {
      setPreloader(false);
    }
  };

  return (
    <div className="contact-us">
      <NotificationContainer />
      <h1>
        {/* eslint-disable-next-line */}
        <a name="contact-us" />
        Contact EPIC
        <hr />
      </h1>
      <p>Educational Policy Implementing Consultants, Inc.</p>
      {isContactFormVisible ? (
        <div className="form-shown">
          <h1>Contact Us</h1>
          <ContactUsForm onSubmit={onSubmitForm} />
        </div>
      ) : isContactFormSubmitted ? (
        <div className="form-submitted">
          <h1>Contact Us</h1>
          <svg
            viewBox="0 0 64 64"
            fill="currentColor"
            data-ux="Icon"
            class="x-el x-el-svg px_-text-transform-uppercase px_-c-inherit px_-d-inline-block px_-w-72px px_-h-72px px_-fill-419a18 px_-ff-_Fjalla_One___arial__sans-serif px_-fs-16 _mdpx_-fs-16 _lgpx_-fs-16 x-d-ux"
          >
            <g stroke="none" stroke-width="1" fill-rule="evenodd">
              <path d="M28.7573593,37.7279221 L21.6820915,30.6526542 C20.9045947,29.8751574 19.638913,29.8758057 18.8578644,30.6568542 C18.0713705,31.4433482 18.0749354,32.7023524 18.8536644,33.4810814 L27.3473458,41.9747628 C27.735067,42.362484 28.2441903,42.5566956 28.7540749,42.557118 C29.2694396,42.5599505 29.7772579,42.3648778 30.1653817,41.976754 L47.1483268,24.9938088 C47.9200256,24.2221101 47.9231842,22.9526215 47.1421356,22.1715729 C46.3556417,21.385079 45.097529,21.3877524 44.3198997,22.1653817 L28.7573593,37.7279221 Z"></path>
              <path d="M32,64 C49.673112,64 64,49.673112 64,32 C64,14.326888 49.673112,0 32,0 C14.326888,0 0,14.326888 0,32 C0,49.673112 14.326888,64 32,64 Z M32,60 C47.463973,60 60,47.463973 60,32 C60,16.536027 47.463973,4 32,4 C16.536027,4 4,16.536027 4,32 C4,47.463973 16.536027,60 32,60 Z"></path>
            </g>
          </svg>
          <p>
            Thank you for your inquiry! <br />
            We will get back to you within 48 hours.
          </p>
        </div>
      ) : (
        <Button variant="primary" onClick={onShowForm}>
          Contact Us
        </Button>
      )}
    </div>
  );
};

export default main(ContactUs);
