import React from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import main from '../../containers/main';
import {Field, reduxForm} from 'redux-form';
import {required} from '../../utils';
import './styles.scss';
import Select from '../Select/Select';


let UserForm = (props) => {
  const { handleSubmit, schools } = props;
  const schoolsList = schools.map(item => {
    return {
      key: item._id,
      value: item.name
    }
  })
  return (
    <div className="school-form">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">User Email</label>
          <div className="col-sm-8">
            <Field
              name="email"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">School</label>
          <div className="col-sm-8">
            <Field
              name="school_id"
              className="input"
              component={Select}
              type="text"
              options={schoolsList}
              validate={[required]}
              />
          </div>
        </div>
        
        <Button
            className="btn btn-primary submit-btn"
            type="submit"
          >
          Submit
        </Button>
      </form>
    </div>
  );
}
UserForm = reduxForm({
  form: 'UserForm'
})(UserForm)

export default main(UserForm);