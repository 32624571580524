import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  UPDATE_USER,
  HIDE_NOTIFICATION
} from '../actions/types';

const INITIAL_STATE = {
  token: null,
  user: null,
  roleName: null
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user,
        roleName: action.user.roleName
      }

    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        roleName: null
      }

    case UPDATE_USER:
      return {
        ...state,
        user: action.user
      }

    case HIDE_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: state.user.notifications.filter((notification, index)=> {
            return (index !== action.index)
          })
        }
      }
    default:
      return state
  }
}