import { useState } from 'react';
export default initialValue => {
  const [witnessArray, setWitnessArray] = useState(initialValue);

  return {
    witnessArray,
    addWitness: witness => {
      setWitnessArray([...witnessArray, witness]);
    },
    removeWitness: id => {
      const newArray = witnessArray
      .filter((_, index) => {
        return index !== id
      });
      setWitnessArray(newArray);
    }
  };
};