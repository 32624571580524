import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import './styles.scss';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const Input = (props) => {
  const {
    className,
    placeholder,
    input,
    meta,
    type,
    updateNumber,
    data,
    disabled,
    max,
    maxLength
  } = props;

  const {touched, error, active} = meta;
  let {value} = input;
  const handler = (val) => {
    if (type === 'subdomain') {
      val.target.value = val.target.value.replace(/\s/g, '');
      val.target.value = val.target.value.toLowerCase();
      // eslint-disable-next-line no-useless-escape
      val.target.value = val.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    }
    if (type === 'number') {
      if (!val.target.value.length) {
        val.target.value = '';
        return
      }
      if (maxLength && val.target.value.length > maxLength) val.target.value = val.target.value.slice(0, +maxLength)
    }
    if (updateNumber) {
      if (val.target.value <= +max) updateNumber(val.target.value);
    }
  }
  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });

  if (data) input.onChange(data);
  
  return (
    <div className={style}>
      <div className="input__error-message">{error}</div>
      {
        type === "tel" 
          ? <InputMask
              mask="(999) 999-99-99"
              className="input__field form-control"
              placeholder={placeholder}
              type={type}
              onInput={handler}
              {...input}
            />
          : <input
              className="input__field form-control"
              placeholder={placeholder}
              disabled={disabled}
              type={type}
              onInput={handler}
              value={data}
              max={max}
              maxLength={maxLength}
              {...input}
            />
        }
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;