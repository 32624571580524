import React, { useState, useEffect, useCallback } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import onSubmit from './onSubmit';
import main from '../../containers/main';
import './Login.scss';
import { getSubdomain } from '../../utils';
import { APP_URL } from '../../config/index';
import Cookies from 'universal-cookie';

const Login = (props) => {
  const {
    signInSuccess,
    setPreloader,
    setDistricts,
    setUsers,
    setSchools,
    setComplaints,
    history,
    setLogout,
  } = props;

  const [response, setResponse] = useState(null);

  const redirectToPath = useCallback(
    (roleName) => {
      roleName
        ? roleName === 'superAdmin'
          ? setTimeout(() => {
              history.push('/districts');
            })
          : roleName === 'districtAdmin'
          ? setTimeout(() => {
              history.push('/complaints');
            })
          : roleName === 'user'
          ? setTimeout(() => {
              history.push('/complaints');
            })
          : setTimeout(() => {
              history.push('/login');
            })
        : setTimeout(() => {
            history.push('/login');
          });
    },
    [history]
  );

  useEffect(() => {
    const cookies = new Cookies();
    const cookiesOptions = {
      domain: '.' + process.env.REACT_APP_HOST,
      path: '/',
    };

    const token = cookies.get('token');
    const roleName = cookies.get('roleName');
    const user = cookies.get('user');

    // If it is redirection, just update entire page to get data.
    if (token && roleName) {
      cookies.remove('token', cookiesOptions);
      cookies.remove('roleName', cookiesOptions);
      cookies.remove('user', cookiesOptions);

      setLogout(false);
      localStorage.setItem('token', token);
      localStorage.setItem('roleName', roleName);
      signInSuccess(token, user);

      redirectToPath(roleName);
    }
  }, [redirectToPath, setLogout, signInSuccess]);

  const submit = useCallback(
    async (prop) => {
      setPreloader(true);
      try {
        const loginResponse = await onSubmit(prop);
        setResponse(loginResponse);
      } finally {
        setPreloader(false);
      }
    },
    [setPreloader]
  );

  const redirectToSubdomain = useCallback((subdomain) => {
    const url = subdomain
      ? APP_URL.replace('{subdomain}', subdomain) + '/login'
      : APP_URL.replace('{subdomain}.', '') + '/login';
    window.location = url;
  }, []);

  const processLogin = useCallback(() => {
    if (!response || !response.success) {
      return;
    }

    const currentSubdomain = getSubdomain();
    const loggedSubdomain = response.subdomain;

    if (currentSubdomain !== loggedSubdomain) {
      const cookies = new Cookies();
      const cookiesOptions = {
        domain: '.' + process.env.REACT_APP_HOST,
        path: '/',
        maxAge: process.env.REACT_APP_AUTO_LOGIN_COOKIE_AGE,
      };

      cookies.set('token', response.token, cookiesOptions);
      cookies.set('roleName', response.user.roleName, cookiesOptions);
      cookies.set('user', JSON.stringify(response.user), cookiesOptions);

      redirectToSubdomain(loggedSubdomain);
      return;
    }

    setLogout(false);
    localStorage.setItem('token', response.token);
    localStorage.setItem('roleName', response.user.roleName);
    signInSuccess(response.token, response.user);

    if (response.data.districtsData) setDistricts(response.data.districtsData);
    if (response.data.usersData) setUsers(response.data.usersData);
    if (response.data.schoolsData) setSchools(response.data.schoolsData);
    if (response.data.complaintsData)
      setComplaints(response.data.complaintsData);

    redirectToPath(response.user.roleName);
  }, [
    redirectToPath,
    redirectToSubdomain,
    response,
    setComplaints,
    setDistricts,
    setLogout,
    setSchools,
    setUsers,
    signInSuccess,
  ]);

  useEffect(() => {
    processLogin();
  }, [processLogin]);

  return (
    <div className="login container">
      <LoginForm onSubmit={submit} response={response}></LoginForm>
    </div>
  );
};

export default main(Login);
