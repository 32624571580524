import {
  SET_PRELOADER,
  SET_LOGOUT
} from '../actions/types';

const INITIAL_STATE = {
  preloader: false,
  logout: false
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PRELOADER:
      return {
        ...state,
        preloader: action.preloader
      }
    case SET_LOGOUT:
      return {
        ...state,
        logout: action.logout
      } 

    default:
      return state
  }
}