import React, {useEffect} from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import FormHint from '../FormHint/FormHint';
import {Field, reduxForm, change} from 'redux-form';
import {required, passLength, existsNumber, existsLetter, existsCapital} from '../../utils';
import './styles.scss';

let RegistrationForm = props => {
  const { handleSubmit, login, dispatch, response } = props;
  useEffect(() => {
    dispatch(change('RegistrationForm', 'login', login));
  }, [dispatch, login])
  return (
    <div className="registration-form col-sm-6">
      <form onSubmit={handleSubmit} className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="login">Login</label>
          <div className="col-sm-8">
            <Field
              name="login"
              className="input"
              component={Input}
              type="text"
              disabled
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="firstName">First Name</label>
          <div className="col-sm-8">
            <Field
              name="firstName"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="lastName">Last Name</label>
          <div className="col-sm-8">
            <Field
              name="lastName"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="password">Password</label>
          <div className="col-sm-8">
            <Field
              name="password"
              className="input"
              component={Input}
              type="password"
              validate={[required, passLength, existsNumber, existsLetter, existsCapital]}
              />
            <FormHint />
          </div>
        </div>
        <div className="">
          <Button
            filled
            medium
            className="btn btn-primary submit-btn"
            type="submit"
          >
            Register
          </Button>
        </div>
        {response && !response.success
          ? <div className="alert alert-danger" role="alert">
              {response.message}
            </div>
          : null
        }
      </form>
    </div>
  );
}

RegistrationForm = reduxForm({
  form: 'RegistrationForm'
})(RegistrationForm)

export default RegistrationForm;