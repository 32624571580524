import React, { useState } from 'react';
import ForgotForm from '../../ForgotForm/ForgotForm';
import { Modal, Alert } from 'react-bootstrap';
import onSubmit from './onSubmit';
import './styles.scss';
import main from '../../../containers/main';

const ResetPasswordModal = (props) => {
  const {show, handleClose, setPreloader} = props;
  let [response, setResponse] = useState(null);
  const submit = async (prop) => {
    setPreloader(true);
    const getResponse = await onSubmit({login: prop.email});
    setPreloader(false);
    setResponse(getResponse)
    if (getResponse && getResponse.success) handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Please Enter Registered E-Mail:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ForgotForm onSubmit={submit}></ForgotForm>
          {response && !response.success
            ? <Alert className="col-sm-12" variant="danger">{response.message}</Alert>
            :null
          }
      </Modal.Body>
    </Modal>
  );
};

export default main(ResetPasswordModal);