import React , {useState, useEffect} from 'react';
import main from '../../containers/main';
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import './styles.scss';
import {GET_REPORT, GET_COMPLAINT} from '../../config/index';
import generateAcknowledgement from '../../utils/generateAcknowledgementLetter';

function ComplaintDetails (props) {
  const {complaints, history, user, timeline, token, setPreloader, setReport} = props;
  const complaintID = new URLSearchParams(props.location.search).get("id");
  const [complaintData, setComplaintData] = useState(null);
  const [reportExists, setReportExists] = useState(false);
  const generateAcknowledgementLetter = () => {
    const letterData = {
      complainantName: `${complaintData.registrarFirstName} ${complaintData.registrarLastName}`,
      victimName: `${complaintData.studentFirstName} ${complaintData.studentLastName}`,
      timeline: timeline,
      address: `${complaintData.homeAddress}, ${complaintData.city}, ${complaintData.state}, ${complaintData.zip}`,
      compDate: complaintData.complaintDate,
      userFullName: `${user.firstName} ${user.lastName}`
    }
    generateAcknowledgement(letterData);
  }
  const getComplaint = async () => {
    setPreloader(true);
    const jsonData = await (await fetch(`${GET_COMPLAINT}?id=${complaintID}`, {
      method: 'get',
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
    })).json();

    if (jsonData.success) {
      setComplaintData(jsonData.complaint);
    } else if (!jsonData.success && jsonData.code === 401) {
      history.push('/login');
    }
    setPreloader(false);
  }
  const getReport = async () => {
    setPreloader(true);
    const jsonData = await (await fetch(`${GET_REPORT}?id=${complaintID}`, {
      method: 'get',
      headers: {'Authorization': `Bearer ${token}`},
    })).json();
    if (jsonData.success) {
      setReport(jsonData.report);
      setReportExists(true);
    } else if (!jsonData.success && jsonData.code === 401) {
      history.push('/login');
    } else {
      setReport({});
      setReportExists(false);
    }
    setPreloader(false);
  }
  useEffect(() => {
    let complaint = complaints.filter(val => {
      return val._id === complaintID;
    })[0];
    if (complaint) {
      setComplaintData(complaint)
      getReport();
    } else {
      getComplaint();
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="container complaint-detail">
      <div className="row page-header">
        <h3>Complaint Details</h3>
        <Button
          variant="info"
          onClick={() => history.push('/complaints')}
        >
          Back
        </Button>
      </div>
      <div className="row">
        <div className="complaint-detail__content ">
          {complaintData
            ? <>
                <p><b>Complaint ID:</b> {complaintData._id}</p>
                <p><b>Complaint Date:</b> {moment(complaintData.complaintDate).format('MM/DD/YYYY')}</p>
                <p><b>Complaint Status:</b> {complaintData.complaintStatus}</p>
                <p><b>Complaint Registered By:</b> {complaintData.complaintRegisteredBy}</p>
                <p><b>First Name:</b> {complaintData.registrarFirstName}</p>
                <p><b>Last Name:</b> {complaintData.registrarLastName}</p>
                <p><b>Student First Name:</b> {complaintData.studentFirstName}</p>
                <p><b>Student Last Name:</b> {complaintData.studentLastName}</p>
                <p><b>Home Address:</b> {complaintData.homeAddress}</p>
                <p><b>City:</b> {complaintData.city}</p>
                <p><b>State:</b> {complaintData.state}</p>
                <p><b>Zip:</b> {complaintData.zip}</p>
                <p><b>Home Phone:</b> {complaintData.homePhone}</p>
                <p><b>Cell Phone:</b> {complaintData.cellPhone}</p>
                <p><b>Work Phone:</b> {complaintData.workPhone}</p>
                <p><b>E-Mail:</b> {complaintData.email}</p>
                <p><b>Student School Name:</b> {complaintData.schoolName}</p>
                <p><b>Number of Incidents:</b> {complaintData.numberOfIncidents}</p>
                {complaintData.incidents.length
                  ? complaintData.incidents.map((el, i) => (
                    <div key={i}>
                      <hr key={i}/>
                      <h2>Incident {i+1}</h2>
                      <h5>Incident Date:</h5>
                      <p>{moment(el.date).format('MM/DD/YYYY')}</p>
      
                      <h5>Nature of Incident:</h5>
                      {el.nature.map((item, key) => (<p key={key}>{item}</p>))}
      
                      <h5>Name(s)/Title(s) of person(s) complained about:</h5>
                      <Table bordered size="sm">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el.accused.length
                            ? el.accused.map((accused, i) => (
                              <tr key={i}>
                                <td>{accused.accusedFirstName}</td>
                                <td>{accused.accusedLastName}</td>
                                <td>{accused.accusedTitle}</td>
                              </tr>
                            ))
                            : null
                          }
                        </tbody>
                      </Table>
                      <h5>Witnesses:</h5>
                      <Table bordered size="sm">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el.witness.length
                            ? el.witness.map((witness, i) => (
                              <tr key={i}>
                                <td>{witness.witnessFirstName}</td>
                                <td>{witness.witnessLastName}</td>
                                <td>{witness.witnessTitle}</td>
                              </tr>
                            ))
                            : null
                          }
                        </tbody>
                      </Table>
                      <h5>Place of Incident:</h5>
                      <p>{el.place}</p>
                      <h5>Description of Incident:</h5>
                      <p>{el.description}</p>
                      <h5>Relief Sought:</h5>
                      <p>{el.reliefSought}</p>
                    </div>
                  ))
                    
                  : null
                }
                <Button onClick={() => history.push(`/report-form/?id=${complaintData._id}`)}>{reportExists ? 'Investigation Report' : 'Begin Investigation Report'}</Button>  <Button onClick={generateAcknowledgementLetter}>Generate Acknowledgement Letter</Button>
              </>
            : null
          }
          
        </div>
      </div>
    </div>
  )
}
export default main(ComplaintDetails);