import {
  SET_DISTRICTS,
  ADD_DISTRICT,
  TOGGLE_DISTRICT,
  CHANGE_DISTRICT_ADMIN,
  CHANGE_DISTRICT_TIMELINE
} from '../actions/types';

const INITIAL_STATE = {
  districts: [],
  pages: 1
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DISTRICTS:
      return {
        ...state,
        districts: action.districtsData.districts,
        pages: action.districtsData.pages
      }

    case ADD_DISTRICT:
      return {
        ...state,
        districts: [...state.districts, action.district],
      }
    
    case TOGGLE_DISTRICT:
      return {
        ...state,
        districts: state.districts.map(district => {
          return (district._id === action.district._id)
            ? {...district, enable: !district.enable}
            : district
        })
      }
    
    case CHANGE_DISTRICT_ADMIN:
      return {
        ...state,
        districts: state.districts.map(district => {
          return (district._id === action.district._id)
            ? {...district, adminEmail: action.district.adminEmail}
            : district
        })
      }
    
    case CHANGE_DISTRICT_TIMELINE:
      return {
        ...state,
        districts: state.districts.map(district => {
          return (district.subdomainName === action.district.subdomainName)
            ? {...district, timeline: action.district.timeline}
            : district
        })
      }

    default:
      return state
  }
}