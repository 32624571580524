
import React from 'react';
import Input from '../Input/Input';
import {Field, change} from 'redux-form';
import Select from '../Select/Select';
import {required} from '../../utils';
import { Form, Col, Row, Button } from 'react-bootstrap';
import {typesOfTitle} from '../../utils/configData';
import './styles.scss';


let WitnessList= (props) => {
  const {witnessArray, number, dispatch, removeWitness} = props;

  return (
    <>
      {witnessArray.map((el,i) => (
        <div className="witness-data" key={el}>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Witness First Name</Form.Label>
            <Col sm={4}>
              <Field
                name={`incident-${number}__witnessFirstName-${el}`}
                // placeholder="Registrar First Name"
                className="input"
                component={Input}
                type="text"
                validate={[required]}
                />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3" >Witness Last Name</Form.Label>
            <Col sm={4}>
              <Field
                name={`incident-${number}__witnessLastName-${el}`}
                // placeholder="Registrar Last Name"
                className="input"
                component={Input}
                type="text"
                validate={[required]}
                />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">Title</Form.Label>
            <Col sm={4}>
              <Field
                name={`incident-${number}__witnessTitle-${el}`}
                type="select"
                // placeholder="Email"
                className="input"
                options={typesOfTitle}
                component={Select}
                validate={[required]}
                />  
            </Col>
          </Form.Group>
          {(witnessArray.length - 1) === i && i !== 0
            ? <Form.Group as={Row}>
                <Form.Label column sm="3"></Form.Label>
                <Col sm={4}>
                  <Button
                    variant="danger"
                    onClick={()=>{
                      dispatch(change('ComplaintForm',`incident-${number}__witnessFirstName-${el}`, ''));
                      dispatch(change('ComplaintForm',`incident-${number}__witnessLastName-${el}`, ''));
                      dispatch(change('ComplaintForm',`incident-${number}__witnessTitle-${el}`, ''));
                      removeWitness(el)
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              </Form.Group>
            : null
          }
          
        </div>
      ))}
    </>
  );
}

export default WitnessList;