import React from 'react';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import main from '../../containers/main';
import onUpdateUser from './onUpdateUser';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import './styles.scss';

function Profile (props) {
  const { setPreloader, user, token, updateUser } = props;
  const handleUpdateUser = async (prop) => {
    setPreloader(true);
    const data = {
      userID: user._id,
      updatedData: {
        firstName: prop.firstName,
        lastName: prop.lastName,
        phone: prop.phone
      }
    }
    const response = await onUpdateUser(data, token);

    if (response.success) {
      for(var s in data.updatedData){
        user[s] = data.updatedData[s] || '';
      }
      updateUser(user)
      createNotification({type: 'success', message: response.message});
    } else {
      createNotification({type: 'error', message: response.message});
    }
    setPreloader(false);
  }
  return (
    <div className="container">
      <NotificationContainer/>
      <div className="row page-header">
        <h3>User Profile</h3>
      </div>
      <div className="row">
        {user 
          ? <ProfileForm onSubmit={handleUpdateUser}/>
          : null
        }
        
      </div>
    </div>
  )
}
export default main(Profile);