import React from 'react';
import SchoolForm from '../../SchoolForm/SchoolForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';
import onSubmit from './onSubmit';

import './styles.scss';


const ModalAddSchool = (props) => {
  const {
    token,
    addSchool,
    setPreloader,
    handleClose,
    show
  } = props;
  const submit = async (prop) => {
    setPreloader(true);
    const getResponse = await onSubmit(prop, token);
    if (getResponse && getResponse.success) {
      addSchool(getResponse.school);
      handleClose();
    } 
    setPreloader(false);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>School Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SchoolForm onSubmit={submit}></SchoolForm>
      </Modal.Body>
    </Modal>
  );
};

export default main(ModalAddSchool);