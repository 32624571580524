import { useState } from 'react';
export default initialValue => {
  const [accusedArray, setAccusedArray] = useState(initialValue);

  return {
    accusedArray,
    addAccused: accused => {
      setAccusedArray([...accusedArray, accused]);
    },
    removeAccused: id => {
      const newArray = accusedArray
      .filter((_, index) => {
        return index !== id
      });
      setAccusedArray(newArray);
    }
  };
};