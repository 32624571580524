import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormControl  } from 'react-bootstrap';
import './styles.scss';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const Select = (props) => {
  const {
    className,
    input,
    meta,
    options,
    data,
    disabled
  } = props;
  const {touched, error, active} = meta;
  let {value} = input;

  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });
  
  if (data) input.onChange(data)
  
  return (
    <div className={style}>
      <div className="input__error-message">{error}</div>
      <FormControl className="input__field" disabled={disabled} {...input} as="select">
        <option value="" disabled >Please Select...</option>
        {options.map(el => (
          typeof(el) === 'object'
            ? <option onChange={input.onChange } key={el.key} value={el.key}>{ el.value }</option>
            : <option onChange={input.onChange } key={el} value={el}>{ el }</option>
          
        ))}
      </FormControl>
    </div>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;