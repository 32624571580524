import React from 'react';
import UsersList from '../../components/UsersList/UsersList'
import './styles.scss';

function Users (props) {
  return (
    <div className="container">
      <div className="row page-header">
        <h3>User List</h3>
      </div>
      <div className="row">
        <UsersList />
      </div>
    </div>
  );
}
export default Users;