import React, {useState, useEffect} from 'react';
import './styles.scss';
import Preloader from '../../components/Preloader/Preloader';
import main from '../../containers/main';
import { Router, Route, Switch } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import Home from '../../pages/Home/Home';
import Login from '../../pages/Login/Login';
import Districts from '../../pages/Districts/Districts';
import Content from '../../pages/Content/Content';
import Schools from '../../pages/Schools/Schools';
import Users from '../../pages/Users/Users';
import { Container } from 'react-bootstrap';
import Registration from '../../pages/Registration/Registration';
import Reset from '../../pages/ResetPassword/ResetPassword';
import Complaints from '../../pages/Complaints/Complaints';
import ComplaintDetails from '../../pages/ComplaintDetails/ComplaintDetails';
import Complaint from '../../pages/Complaint/Complaint';
import Profile from '../../pages/Profile/Profile';
import Audit from '../../pages/Audit/Audit';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import getSubdomain from '../../utils/getSubdomain';
import Report from '../../pages/Report/Report';
import fetchData from '../../utils/fetchData';
const Layout = (props) => {
  const {preloader,
    token,
    roleName,
    signOut,
    history,
    setPreloader,
    setDistricts,
    setUsers,
    setSchools,
    setComplaints,
    signInSuccess,
    setLogout
  } = props;
  const [showHeader, setShowHeader] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let fetchInfo = async () => {
    if (localStorage.getItem('token')) {
      setPreloader(true);
      const jsonData = await fetchData();
      if (jsonData && jsonData.success) {
        localStorage.setItem('token', jsonData.token);
        localStorage.setItem('roleName', jsonData.user.roleName);
        signInSuccess(jsonData.token, jsonData.user);
        if (jsonData.data.districtsData) setDistricts(jsonData.data.districtsData);
        if (jsonData.data.usersData) setUsers(jsonData.data.usersData);
        if (jsonData.data.schoolsData) setSchools(jsonData.data.schoolsData);
        if (jsonData.data.complaintsData) setComplaints(jsonData.data.complaintsData);
      } else {
        if (history.location.pathname !== '/' || history.location.pathname !== '/complaint') history.push('/');
      }
      setPreloader(false);
    } 
  }

  useEffect(() => {
    if (history.location.pathname === '/complaint') setShowHeader(false);
    else fetchInfo(); 
    if (localStorage.getItem('token')) setIsLoggedIn(true);
    else setIsLoggedIn(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
 
  useEffect(() => {
    var no_active_delay = 30; 
    var now_no_active = 0; 
    setInterval(() => notActive() , 60 * 1000); 
    setInterval(() => checkActivity(), 60 * 1000); 
    document.onmousemove = activeUser;
    function activeUser() {
      now_no_active = 0;
    }
    function notActive() {
      now_no_active++;
    }
    function checkActivity() {
      if (now_no_active >= no_active_delay) {
        now_no_active = 0;
        if (history.location.pathname !== '/login'
          && history.location.pathname !== '/registration'
          && history.location.pathname !== '/complaint'
          && history.location.pathname !== '/reset'
          && history.location.pathname !== '/report-form/') {
          signOut();
          history.push('/login');
        } else if (history.location.pathname === '/report-form/') {
          setLogout(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const subdomain = getSubdomain();
  return (
    <div className="layout">
      {preloader 
        ? <Preloader />
        : null
      }
      <div className="layout__wrapper">
        <div className="layout__content">
          <Container fluid>
            <Router history={history}>
              {showHeader
                ? <Navigation history={history}/>
                : null
              }
              <Content showHeader={showHeader}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  {subdomain
                    ? <Route path="/complaint" component={Complaint} />
                    : null
                  }
                  <Route path="/login" component={Login} />
                  <Route path="/registration" component={Registration} />
                  <Route path="/reset" component={Reset} />
                  {token 
                    ? <>
                        <PrivateRoute history={history} access={["districtAdmin"]} path={"/logging"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Audit} />
                        <PrivateRoute history={history} access={["superAdmin", "districtAdmin", "user"]} path={"/profile"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Profile} />
                        <PrivateRoute history={history} access={["districtAdmin"]} path={"/schools"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Schools} />
                        <PrivateRoute history={history} access={["superAdmin", "districtAdmin"]} path={"/users"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Users} />
                        <PrivateRoute history={history} access={["superAdmin"]} path={"/districts"} roleName={ roleName } token={ token } isLoggedIn={isLoggedIn} component={Districts} />
                        <PrivateRoute history={history} access={["districtAdmin", "user"]} path={"/complaint-info"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={ComplaintDetails} />
                        <PrivateRoute history={history} access={["districtAdmin", "user"]} path={"/report-form"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Report} />
                        <PrivateRoute history={history} access={["districtAdmin", "user"]} path={"/complaints"} roleName={ roleName } isLoggedIn={isLoggedIn} token={ token } component={Complaints} />
                      </>
                    : null
                  }
                </Switch>
              </Content>
            </Router>
          </Container>  
        </div>
      </div>

    </div>
  );
}

export default main(Layout);