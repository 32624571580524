import React, {useState}  from 'react';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import onSubmit from './onSubmit';
import './styles.scss';
import main from '../../containers/main';

let userLogin, userToken;

const ResetPassword = (props) => {
  const {setPreloader} = props;
  const [response, setResponse] = useState(null)
  const submit = async (prop) => {
    setPreloader(true);
    prop.login = userLogin;
    prop.token = userToken;
    const getResponse = await onSubmit(prop);
    setPreloader(false);
    setResponse(getResponse);
    if (getResponse && getResponse.success) props.history.push('/login');
    return getResponse;
  }
  userLogin = new URLSearchParams(props.location.search).get("email");
  userToken = new URLSearchParams(props.location.search).get("token");
  return (
    <div className="registration container">
      <ResetPasswordForm onSubmit={submit} login={userLogin} response={response} ></ResetPasswordForm>
    </div>
  );
}

export default main(ResetPassword);