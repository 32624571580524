import React from 'react';
import main from '../../containers/main';
import ComplaintsList from '../../components/ComplaintsList/ComplaintList';
import './styles.scss';

function Complaints (props) {
  const {history} = props
  

  return (
    <>
      <div className="container">
        <div className="row page-header">
          <h3>Complaint List</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <ComplaintsList history={history}/>
        </div>
      </div>
    </>
  )
}
export default main(Complaints);