import {Document, Paragraph, Packer, TextRun} from 'docx';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default (data) => {
  const doc = new Document();
  doc.addSection({
    properties: {},
    children: [
      new Paragraph({children: [new TextRun(`${moment().format('MMMM DD, YYYY')}`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`VIA HAND DELIVERY & CERTIFIED MAIL`)]}),
      new Paragraph({children: [new TextRun(`Address: ${data.address ? `${data.address}, ${data.state}, ${data.city}, ${data.zip}` : ''} `)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`     RE: DASA Complaint`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`Dear ${data.name}:`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`As the District’s Dignity Act (DASA) Coordinator, I am charged with the responsibility of investigating complaints of discrimination and/or bullying.`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`You have been identified as an individual with potential information relating to an ongoing DASA investigation. Please contact me at your earliest convenience to schedule an interview.`)]}),
      new Paragraph({children: [new TextRun(``)]}),
      new Paragraph({children: [new TextRun(`Sincerely,`)]}),
      new Paragraph({children: [new TextRun(`${data.userFullName}`)]}),
    ],
  });
  
  Packer.toBlob(doc).then(blob => {
    saveAs(blob, "Witness Letter.docx");
  });
}