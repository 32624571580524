import React from 'react';
import './styles.scss';

export default function Content (props) {
  const {showHeader} = props;
  return (
    <div className={`content ${showHeader ? '' : 'hide-header'}`}>
      {props.children}
    </div>
  );
}
