import React from 'react';
import ComplaintForm from '../../components/ComplaintForm/ComplaintForm';
import main from '../../containers/main';
import onSubmit from './onSubmit';
import moment from 'moment';
import createNotification from '../../components/Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import './styles.scss';

function Complaints (props) {
  const { setPreloader } = props;
  const handleSubmit = async (prop) => {
    setPreloader(true);
    prop.complaintDate = moment().format('MM/DD/YYYY');
    const getIncidentsData = () => {
      const obj = {};
      for (let data in prop) {
        if (data.indexOf('incident-') !== -1) obj[data] = prop[data];
      } 
      return obj;
    }
    const incidentsObj = getIncidentsData();
    const getIncidentArray = () => {
      const accused = [];
      for (let i = 1; i <= prop.numberOfIncidents; i++) {
        let incData = {};
        for (let data in incidentsObj) {
          if (data.indexOf(`incident-${i}`) !== -1) {
            if (data.indexOf(`incident-${i}__incidentDate`) !== -1) incData['incidentDate'] = incidentsObj[data];
            else if (data.indexOf(`incident-${i}__nature`) !== -1) incData['nature'] = incidentsObj[data];
            else if (data.indexOf(`incident-${i}__place`) !== -1) incData['place'] = incidentsObj[data];
            else if (data.indexOf(`incident-${i}__description`) !== -1) incData['description'] = incidentsObj[data];
            else if (data.indexOf(`incident-${i}__reliefSought`) !== -1) incData['reliefSought'] = incidentsObj[data];
            else incData[data] = incidentsObj[data];
          }
        }
        incData['incidentNumber'] = i;
        accused.push(incData)
      }
      return accused;
    }
    const incidentArray = getIncidentArray();
    for (let i = 0; i < prop.numberOfIncidents ; i++) {
      let countAccused = 0;
      let countWitness = 0;

      for (let inc in incidentArray[i]) {
        if (inc.indexOf(`incident-${i+1}__accused`) !== -1) countAccused++;
        else if (inc.indexOf(`incident-${i+1}__witness`) !== -1) countWitness++;
      }
      let accused = [];
      let witness = [];
      countAccused = countAccused ? countAccused/3 : 0;
      countWitness = countWitness ? countWitness/3 : 0;
      
      if (countAccused) {
        for (let j = 0; j < countAccused; j++) {
          accused.push({
            accusedFirstName: '',
            accusedLastName: '',
            accusedTitle: ''
          })
          for (let inc in incidentArray[i]) {
            if (inc.indexOf(`incident-${i+1}__accusedFirstName-${j}`) !== -1) accused[j]['accusedFirstName'] = incidentArray[i][inc];
            else if (inc.indexOf(`incident-${i+1}__accusedLastName-${j}`) !== -1) accused[j]['accusedLastName'] = incidentArray[i][inc];
            else if (inc.indexOf(`incident-${i+1}__accusedTitle-${j}`) !== -1) accused[j]['accusedTitle'] = incidentArray[i][inc];
          }
        }
      }
      if (countWitness) {
        for (let j = 0; j < countWitness; j++) {
          witness.push({
            witnessFirstName: '',
            witnessLastName: '',
            witnessTitle: ''
          })
          for (let inc in incidentArray[i]) {
            if (inc.indexOf(`incident-${i+1}__witnessFirstName-${j}`) !== -1) witness[j]['witnessFirstName'] = incidentArray[i][inc];
            else if (inc.indexOf(`incident-${i+1}__witnessLastName-${j}`) !== -1) witness[j]['witnessLastName'] = incidentArray[i][inc];
            else if (inc.indexOf(`incident-${i+1}__witnessTitle-${j}`) !== -1) witness[j]['witnessTitle'] = incidentArray[i][inc];
          }
        }
      }
      incidentArray[i].accused = accused;
      incidentArray[i].witness = witness;
    }
    const getResponse = await onSubmit({incidentArray, complaint: prop});
    if (getResponse.success) {
      createNotification({type: 'success', message: 'Complaint registered successfully'})
    }
    setPreloader(false);
  }
  return (
    <div className="container">
      <NotificationContainer/>
      <div className="row page-header">
        <h3>Register Complaint</h3>
      </div>
      <div className="row">
        <ComplaintForm onSubmit={handleSubmit}/>
      </div>
    </div>
  )
}
export default main(Complaints);