import {SubmissionError} from 'redux-form';
// import parseResponse from 'utils/parseResponse';
// import {SIGN_IN_API} from 'config';
import {UPDATE_DISTRICT} from '../../config/index';

export default async (data) => {
  const response = await fetch(`${UPDATE_DISTRICT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.token}`
    },
    body: JSON.stringify(data),
  });
  try {
    return await response.json();
  } catch (e) {
    throw new SubmissionError(e);
  }
};
