import {SubmissionError} from 'redux-form';
// import parseResponse from 'utils/parseResponse';
// import {SIGN_IN_API} from 'config';
import {LOGIN} from '../../config/index';

export default async (data) => {
  const response = await fetch(`${LOGIN}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  try {
    return await response.json();
  } catch (e) {
    throw new SubmissionError(e);
  }
};
