import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  filled: PropTypes.bool,
  medium: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  onClick: () => {},
  className: '',
  filled: false,
  medium: false,
  type: 'button',
};

const Button = (props) => {
  const {
    children,
    onClick,
    className,
    filled,
    medium,
    type,
  } = props;

  const style = classnames({
    [className]: className,
    button: true,
    button_filled: filled,
    button_medium: medium,
  });

  return (
    /* eslint-disable react/button-has-type */
    <button className={style} onClick={onClick} type={type}>
      {children}
    </button>
    /* eslint-enable react/button-has-type */
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
