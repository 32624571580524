import React from 'react';
import SchoolsList from '../../components/SchoolsList/SchoolsList'
import './styles.scss';

function Schools (props) {
  return (
    <div className="container">
      <div className="row page-header">
        <h3>School List</h3>
      </div>
      <div className="row">
        <SchoolsList />
      </div>
    </div>
  );
}
export default Schools;