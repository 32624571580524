import React from 'react';
import DistrictForm from '../../DistrictForm/DistrictForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';

const ModalEdit = (props) => {
  const {
    handleClose,
    show,
    district,
    handleEdit
  } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
          <Modal.Title>District Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DistrictForm handleClose={handleClose} editMode={true} district={district} onSubmit={handleEdit}></DistrictForm>
        </Modal.Body>
    </Modal>
  );
};

export default main(ModalEdit);