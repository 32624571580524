// import React from 'react';
import {NotificationManager} from 'react-notifications';

let Notification = (data) => {
  switch (data.type) {
    case 'info':
      NotificationManager.info(`${data.message}`, '', 3000);
      break;
      case 'success':
      NotificationManager.success(`${data.message}`, '', 3000);
      break;
    case 'warning':
      NotificationManager.warning(`${data.message}`, '', 3000);
      break;
    case 'error':
      NotificationManager.error(`${data.message}`, '', 3000);
      break;
    default: break;
  }
}

export default Notification;