import {DELETE_COMPLAINT} from '../../config/index';

export default async (complaintId, token) => {
  const response = await fetch(`${DELETE_COMPLAINT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      complaintID: complaintId
    }),
  });
  return await response.json();
};
