import React from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import {Field, reduxForm} from 'redux-form';
import {required, email} from '../../utils';
import './styles.scss';

let ForgotForm = (props) => {
  const { handleSubmit } = props;
  
  return (
    <div className="district-form">
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="email">E-mail</label>
          <div className="col-sm-8">
            <Field
              name="email"
              className="input"
              component={Input}
              type="email"
              validate={[required, email]}
              />
          </div>
        </div>
       
        <Button
            className="btn btn-primary submit-btn"
            type="submit"
          >
          Submit
        </Button>
      </form>
    </div>
  );
}
ForgotForm = reduxForm({
  form: 'ForgotForm'
})(ForgotForm)

export default ForgotForm;