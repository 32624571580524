export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const ADD_DISTRICT = 'ADD_DISTRICT';
export const TOGGLE_DISTRICT = 'TOGGLE_DISTRICT';
export const SET_PRELOADER = 'SET_PRELOADER';
export const SET_USERS = 'SET_USERS';
export const TOGGLE_USER = 'TOGGLE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_SCHOOLS = 'SET_SCHOOLS';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const SET_COMPLAINTS = 'SET_COMPLAINTS';
export const DELETE_COMPLAINT = 'DELETE_COMPLAINT';
export const SET_REPORT = 'SET_REPORT';
export const UPDATE_COMPLAINT = 'UPDATE_COMPLAINT';
export const CHANGE_DISTRICT_ADMIN = 'CHANGE_DISTRICT_ADMIN';
export const CHANGE_DISTRICT_TIMELINE = 'CHANGE_DISTRICT_TIMELINE';
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_LOGOUT = 'SET_LOGOUT';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';



