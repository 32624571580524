
import React from 'react';
import Input from '../Input/Input';
import {Field, change} from 'redux-form';
import Select from '../Select/Select';
import {required} from '../../utils';
import { Form, Col, Row, Button } from 'react-bootstrap';
import {typesOfTitle} from '../../utils/configData';
import './styles.scss';


let AccusedList= (props) => {
  const {accusedArray, number, dispatch, removeAccused} = props;

  
  return (
    <>
      {accusedArray.map((el,i) => (
        <div className="accused-data" key={el}>
        <Form.Group as={Row}>
          <Form.Label column sm="3">First Name of the Accused</Form.Label>
          <Col sm={4}>
            <Field
              name={`incident-${number}__accusedFirstName-${el}`}
              // placeholder="Registrar First Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">Last Name of the Accused</Form.Label>
          <Col sm={4}>
            <Field
              name={`incident-${number}__accusedLastName-${el}`}
              // placeholder="Registrar Last Name"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">Title of the Accused</Form.Label>
          <Col sm={4}>
            <Field
              name={`incident-${number}__accusedTitle-${el}`}
              type="select"
              // placeholder="Email"
              className="input"
              options={typesOfTitle}
              component={Select}
              validate={[required]}
              />  
          </Col>
        </Form.Group>
        {(accusedArray.length - 1) === i && i !== 0
          ? <Form.Group as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm={4}>
                <Button
                  variant="danger"
                  onClick={()=>{
                    dispatch(change('ComplaintForm',`incident-${number}__accusedFirstName-${el}`, ''));
                    dispatch(change('ComplaintForm',`incident-${number}__accusedLastName-${el}`, ''));
                    dispatch(change('ComplaintForm',`incident-${number}__accusedTitle-${el}`, ''));
                    
                    removeAccused(el)
                  }}
                >
                  Remove
                </Button>
              </Col>
            </Form.Group>
          : null
        }
        
      </div>
      ))}
    </>
  );
}

export default AccusedList;