import React from 'react';
import SchoolForm from '../../SchoolForm/SchoolForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';
import './styles.scss';

const ModalEditSchool = (props) => {
  const {
    handleClose,
    show,
    handleUpdateSchool,
    school,
  } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>School Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SchoolForm school={school} onSubmit={handleUpdateSchool}></SchoolForm>
      </Modal.Body>
    </Modal>
  );
};

export default main(ModalEditSchool);