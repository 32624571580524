import {SubmissionError} from 'redux-form';
import {RESTORE_PASSWORD} from '../../config/index';

export default async (data, token) => {
  const response = await fetch(`${RESTORE_PASSWORD}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  try {
    return await response.json();
  } catch (e) {
    throw new SubmissionError(e);
  }
};
