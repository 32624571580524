import {createStore, combineReducers, applyMiddleware} from 'redux';
import {reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import auth from './auth';
import application from './application';
import districtsData from './districts';
import usersData from './users';
import schoolsData from './schools';
import complaintsData from './complaints';
import report from './report';

const rootReducer = combineReducers({
  schoolsData,
  complaintsData,
  usersData,
  application,
  auth,
  districtsData,
  report,
  form: formReducer,
});

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);