import {SubmissionError} from 'redux-form';
import {CREATE_DISTRICT} from '../../../config/index';

export default async (data) => {
  const body = {
    districtData: data.prop
  }
  const response = await fetch(`${CREATE_DISTRICT}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.token}`
    },
    body: JSON.stringify(body),
  });
  try {
    return await response.json();
  } catch (e) {
    throw new SubmissionError(e);
  }
};
