const appBase =
  process.env.REACT_APP_PROTOCOL +
  '://' +
  '{subdomain}.' +
  process.env.REACT_APP_HOST +
  (process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : '');

const apiBase =
  process.env.REACT_APP_API_PROTOCOL +
  '://' +
  process.env.REACT_APP_API_HOST +
  (process.env.REACT_APP_API_PORT ? ':' + process.env.REACT_APP_API_PORT : '');

const APP_URL = appBase;
const SEND_MESSAGE = `${apiBase}/api/v1/admin/send-message`;
const REGISTER = `${apiBase}/api/v1/admin/register`;
const RESTORE_PASSWORD = `${apiBase}/api/v1/admin/restore-password`;
const RESET_PASSWORD = `${apiBase}/api/v1/admin/reset-password`;
const LOGIN = `${apiBase}/api/v1/auth/login`;
const LOGOUT = `${apiBase}/api/v1/auth/logout`;
const UPDATE_DATA = `${apiBase}/api/v1/auth/updateData`;

const GET_DISTRICTS = `${apiBase}/api/v1/admin/get-districts`;
const CREATE_DISTRICT = `${apiBase}/api/v1/admin/district/create`;
const UPDATE_DISTRICT = `${apiBase}/api/v1/admin/district/update`;
const EDIT_TIMELINE = `${apiBase}/api/v1/admin/edit-timeline`;

const UPDATE_USER = `${apiBase}/api/v1/admin/user/update`;
const DELETE_USER = `${apiBase}/api/v1/admin/user/delete`;
const ADD_USER_ROLE = `${apiBase}/api/v1/admin/user/add-role`;
const GET_USERS = `${apiBase}/api/v1/admin/get-users`;
const NOTIFY_USERS = `${apiBase}/api/v1/complaint/notify`;
const SEND_REGISTRATION_REQUEST = `${apiBase}/api/v1/admin/send-registration-request`;

const CREATE_COMPLAINT = `${apiBase}/api/v1/complaint/create`;
const DELETE_COMPLAINT = `${apiBase}/api/v1/complaint/delete`;
const SEARCH_COMPLAINT = `${apiBase}/api/v1/complaint/search`;
const GET_COMPLAINTS = `${apiBase}/api/v1/complaint/get-complaints`;
const GET_COMPLAINT = `${apiBase}/api/v1/complaint/get`;

const CREATE_SCHOOL = `${apiBase}/api/v1/user/school/create`;
const GET_SCHOOLS = `${apiBase}/api/v1/user/get-schools`;
const GET_DISTRICT_SCHOOLS_LIST = `${apiBase}/api/v1/user/get-district-schools-list`;

const GENERATE_ANNUAL_REPORT = `${apiBase}/api/v1/report/generate-annual-report`;
const GENERATE_REPORT = `${apiBase}/api/v1/report/generate`;
const CREATE_REPORT = `${apiBase}/api/v1/report/create`;
const GET_REPORT = `${apiBase}/api/v1/report/read`;

const GET_LOGGING = `${apiBase}/api/v1/report/get-logging`;

const REMOVE_NOTIFICATION = `${apiBase}/api/v1/user/remove-notification`;
const RESEND_INVITATION = `${apiBase}/api/v1/admin/user/resend-invitation`;
const UPDATE_SCHOOL = `${apiBase}/api/v1/user/school/update`;

export {
  APP_URL,
  SEND_MESSAGE,
  UPDATE_DATA,
  REGISTER,
  RESTORE_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER,
  DELETE_USER,
  ADD_USER_ROLE,
  GET_USERS,
  GET_DISTRICTS,
  CREATE_DISTRICT,
  UPDATE_DISTRICT,
  LOGIN,
  LOGOUT,
  CREATE_COMPLAINT,
  DELETE_COMPLAINT,
  NOTIFY_USERS,
  GET_COMPLAINTS,
  CREATE_SCHOOL,
  GET_SCHOOLS,
  EDIT_TIMELINE,
  GET_DISTRICT_SCHOOLS_LIST,
  GENERATE_ANNUAL_REPORT,
  GENERATE_REPORT,
  CREATE_REPORT,
  SEARCH_COMPLAINT,
  GET_REPORT,
  SEND_REGISTRATION_REQUEST,
  GET_LOGGING,
  REMOVE_NOTIFICATION,
  RESEND_INVITATION,
  UPDATE_SCHOOL,
  GET_COMPLAINT,
};
