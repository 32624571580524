import React from 'react';
import UserForm from '../../UserForm/UserForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';
import './styles.scss';

const ModalAddSchool = (props) => {
  const {
    handleClose,
    show,
    handleSendRequestRegistration
  } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm onSubmit={handleSendRequestRegistration}></UserForm>
      </Modal.Body>
    </Modal>
  );
};

export default main(ModalAddSchool);