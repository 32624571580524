import React from 'react';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import FormHint from '../../components/FormHint/FormHint';
import {Field, reduxForm} from 'redux-form';
import {required, passLength, existsNumber, existsLetter, existsCapital} from '../../utils';
import './styles.scss';

let ResetPasswordForm = props => {
  const { handleSubmit, login, response } = props;

  return (
    <div className="reset-form col-sm-6">
      <form onSubmit={handleSubmit} className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="login">Login</label>
          <div className="col-sm-8">
            <Field
              name="login"
              className="input"
              component={Input}
              type="text"
              data={login}
              disabled
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="password">Password</label>
          <div className="col-sm-8">
            <Field
              name="password"
              className="input"
              component={Input}
              type="password"
              validate={[required, passLength, existsNumber, existsLetter, existsCapital]}
              />
            <FormHint />
          </div>
        </div>
        <div className="">
          <Button
            filled
            medium
            className="btn btn-primary submit-btn"
            type="submit"
          >
            Submit
          </Button>
        </div>
        {response && !response.success
          ? <div className="alert alert-danger" role="alert">
              {response.message}
            </div>
          : null
        }
      </form>
    </div>
  );
}

ResetPasswordForm = reduxForm({
  form: 'ResetPasswordForm'
})(ResetPasswordForm)

export default ResetPasswordForm;